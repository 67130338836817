// REACT & DEFAULTS
import { useContext } from "react";

// COMPONENTS, LIBs & UTILs
import { formatToNigerianNaira } from "../../../lib/utlis/helper";

// IMAGES & ICONS
import { HiMinusSmall, HiPlusSmall } from "react-icons/hi2";

// STYLES
import styles from "./OrderCard.module.scss";
import { OrderContext } from "../../../context/OrderContext";

const OrderCard = ({
  imgURL,
  orderName,
  orderDescription,
  orderPrice,
  singleProductsData,
  discountedOrderPrice,
}: TOrderCard) => {
  const { cartState, dispatch } = useContext(OrderContext);
  // console.log('CARTSTATE: ', cartState)

  const quantity =
    cartState?.products.find((item) => item.slug === singleProductsData.slug)
      ?.quantity ?? 0;

  return (
    <figure className={styles.mainOrder}>
      <img src={imgURL} alt={orderName} className={styles.mainOrderImage} />
      <figcaption className={styles.mainOrderCaption}>
        <h2 className={styles.mainOrderTitle}>{orderName}</h2>
        <p className={styles.mainOrderDescription}>{orderDescription}</p>
        <p className={styles.mainOrderPrice}>
          {/* {formatToNigerianNaira(orderPrice)} */}
          <>
            <span
              className={
                discountedOrderPrice > 0 ? "line-through pr-4 text-red-600" : ""
              }
            >
              {formatToNigerianNaira(orderPrice)}
            </span>
            {discountedOrderPrice > 0 && (
              <span>{formatToNigerianNaira(discountedOrderPrice)}</span>
            )}
          </>
        </p>

        <div className={styles.quantityControl}>
          <div className={styles.controlGroup}>
            <button
              onClick={() =>
                dispatch({
                  type: "REDUCE_PRODUCT_QTY_IN_CART",
                  payload: singleProductsData.slug,
                })
              }
              className={styles.quantityMinus}
              disabled={quantity < 1}
            >
              <HiMinusSmall />
            </button>
            <span className={styles.quantity}>{quantity}</span>
            <button
              onClick={() =>
                dispatch({
                  type: "INCREASE_PRODUCT_QTY_IN_CART",
                  payload: singleProductsData,
                })
              }
              className={styles.quantityPlus}
              disabled={quantity === 50}
            >
              <HiPlusSmall />
            </button>
          </div>
        </div>
      </figcaption>
    </figure>
  );
};

export default OrderCard;
