// IMAGES & ICONS
import Preffered from "../../../../assests/images/MealPlan/mealplan_preffered.png";
import Duration from "../../../../assests/images/MealPlan/mealplan_duration.png";
import Recieve from "../../../../assests/images/MealPlan/mealplan_recieve.png";

// STYLES
import styles from "./MealPlansHowSection.module.scss";

const MealPlansHowSection = () => {
  return (
    <section className={styles.mealPlansHowSection}>
      <article className={styles.mealPlansHowContainer}>
        <h2 className={styles.mealPlansHowTitle}>How it Works</h2>

        <div className={styles.mealPlansHowItWorks}>
          <figure className={styles.mealPlansHowItWorksCards}>
            <img
              className={styles.cardImage}
              src={Preffered}
              alt="Select Preferred Meal Plan"
            />

            <figcaption className={styles.cardText}>
              Select Preferred Meal Plan
            </figcaption>
          </figure>

          <figure className={styles.mealPlansHowItWorksCards}>
            <img
              className={styles.cardImage}
              src={Duration}
              alt="Specify Meal Duration"
            />

            <figcaption className={styles.cardText}>
              Specify Meal Duration
            </figcaption>
          </figure>

          <figure className={styles.mealPlansHowItWorksCards}>
            <img
              className={styles.cardImage}
              src={Recieve}
              alt="Receive Freshly prepared Meals"
            />

            <figcaption className={styles.cardText}>
              Receive Freshly Prepared Meals
            </figcaption>
          </figure>
        </div>
      </article>
    </section>
  );
};

export default MealPlansHowSection;
