import { ReactElement, createContext, Context } from "react";
import { useQuery } from "@tanstack/react-query";
import { getproducts } from "../lib/handlers/productHandler";

// export type TProductType = {
//   id: number
//   name: string
//   price: number
//   description: string
//   image: string | Array<string>
//   slug: string
//   categories?: Array<number | string>
//   extras?: Array<number | string>
//   is_active?: boolean

//   // id: number
//   // slug: string
//   // name: string
//   // image: string
//   // description: string
//   // price: number
// }

// export type TUseProductContextType = {
//   productsData: TProductType[]
//   isProductsDataLoading: boolean
//   isProductsDataError: boolean
// }

type TChildrenType = {
  children?: ReactElement | ReactElement[];
};

export const ProductContext: Context<TUseProductContextType> =
  createContext<TUseProductContextType>({
    productsData: [],
    isProductsDataLoading: false,
    isProductsDataError: false,
  });

export const ProductContextProvider = ({
  children,
}: TChildrenType): ReactElement => {
  const {
    data: productsData,
    isLoading: isProductsDataLoading,
    isError: isProductsDataError,
    isSuccess,
  } = useQuery(["products"], () => getproducts());

  const contextValue: TUseProductContextType = {
    productsData: isSuccess && productsData,
    isProductsDataLoading,
    isProductsDataError,
  };
  // console.log('contextValue: ', contextValue)

  return (
    <ProductContext.Provider value={contextValue}>
      {children}
    </ProductContext.Provider>
  );
};
