import about from "../../../../assests/images/AboutPage/Healthy-Food-Made-For-You.webp";

const FoundedIn = () => {
  return (
    <section className="bg-white px-[20px] py-[150px]">
      <div className="container">
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
          <div>
            <h2 className="text-[#041D05] font-[600] text-[36px] leading-[48px] align-middle">
              Healthy Food Made For
              <span className="text-[#FD9F28]"> You</span>
            </h2>

            <p className="w-[95%] text-[#041D05] font-[400] text-[16px] leading-[29px] mt-8">
              At So Fresh, we believe food should be fresh and close to nature
              as possible, because the choices we make about what we eat, where
              it comes from and how it&apos;s prepared have a direct and
              powerful impact on the health and wellbeing of individuals,
              communities and the environment. We understand that nations thrive
              and succeed on the backdrop of an energetic and healthy populace,
              hence beyond food, we will continue to be at the forefront of
              inspiring and motivating everyone to adopt a healthy lifestyle, in
              support of the achievement of the UN Sustainable Development Goals
              2 and 3 in Nigeria.
            </p>
          </div>

          <div>
            <img src={about} alt="about" className="w-full" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FoundedIn;
