import { axiosClient } from "../utlis/axiosClient";

export const getproducts = async () => {
  try {
    const response = await axiosClient.get(`/public/products?page_size=5000`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

export const getFavouriteproducts = async () => {
  try {
    const response = await axiosClient.get(`public/favorite-products`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

export const getSingleProducts = async (slug: string) => {
  try {
    const response = await axiosClient.get(`/public/products?slug=${slug}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data[0];
  } catch (error) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

export const getProductCategories = async () => {
  try {
    const response = await axiosClient.get(`public/categories?page_size=5000`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error: any) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};
// {{sofresh-localhost}}public/categories?slug=ms-jewel-boyer //Single
// {{sofresh-localhost}}public/categories?slug=ms-jewel-boyer //Single

export const getPlans = async () => {
  try {
    const response = await axiosClient.get(`/public/plans?page_size=5000`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

export const getPlanTypes = async (planType: string) => {
  try {
    const response = await axiosClient.get(
      `public${planType}/plan-type?page_size=5000`,
      //  I Used the above path because location.pathname returns '/plans/planType'
      // `public/plans${planType}/plan-type`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return response.data.data;
  } catch (error) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

export const getPlanTypeProduct = async (planType: string, slug: string) => {
  try {
    const { data } = await axiosClient.get(
      `public/plans/${planType}/plan-type?slug=${slug}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return data.data;
  } catch (error) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};
