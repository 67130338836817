// REACT DEFAULTS
import { useState, useContext, ChangeEvent, FormEventHandler } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

// LIB & UTILS
import { registerUser } from "../../../lib/handlers/authhandler";
import { useMutation } from "@tanstack/react-query";
import { AuthContext } from "../../../context/AppContext";

// IMAGES & ICONS
import BrandLogo from "../../../assests/images/sofreshLogo.png";
import AuthImage from "../../../assests/images/AuthPages/authImage.webp";
import { FaArrowLeft, FaArrowRight, FaStar } from "react-icons/fa";

// STYLES
import styles from "./SignUp.module.scss";
import { Oval } from "react-loader-spinner";
import { OrderContext } from "../../../context/OrderContext";
import { toast } from "../../../components/common/Toast/index";

interface IUserStateData {
  name: "";
  email: "";
  address: "";
  phone_no: "";
  password: "";
  confirmPassword: "";
}
interface IUserSignUp {
  name: "";
  email: "";
  address: "";
  phone_no: "";
  password: "";
}

const SignUp = () => {
  const { handleAuthState } = useContext(AuthContext);
  const { cartState } = useContext(OrderContext);
  const [searchParams] = useSearchParams();
  const location = searchParams.get("location");
  const navigate = useNavigate();

  const [userData, setUserData] = useState<IUserStateData>({
    name: "",
    email: "",
    phone_no: "",
    address: "",
    password: "",
    confirmPassword: "",
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const { mutate: signUpUserMutation, isLoading } = useMutation({
    mutationFn: registerUser,

    onSuccess: async (res) => {
      toast.success("User Registration Successful");
      handleAuthState(res);
      if (location) {
        navigate(`/${location}`);
      } else if (cartState.products.length > 0) {
        navigate("/cart");
      } else {
        navigate("/menu");
      }
      // console.log('RQuery_SUCCESS: ', res)
    },
    onError: async (error: any) => {
      // console.error('RQuery_ERROR: ', error)
      toast.error(error);
    },
  });

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (userData.password.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/)) {
      // console.info('Password Passed')
      // toast.info('Strong Password')

      if (userData.password === userData.confirmPassword) {
        const IUserSignUpData: IUserSignUp = {
          name: userData.name,
          email: userData.email,
          password: userData.password,
          phone_no: userData.phone_no,
          address: userData.address,
        };
        signUpUserMutation({ ...IUserSignUpData });
      } else {
        toast.error("Password must match with Confirm Password.");
      }
    }
  };

  return (
    <main className={styles.signUp}>
      <section className={styles.signUpSection}>
        <div className={styles.authFormContainer}>
          <Link className={styles.brandLogoContainer} to={"/"}>
            <img
              className={styles.brandLogo}
              src={BrandLogo}
              alt="brand logo"
              loading="eager"
            />
          </Link>

          <div className={styles.formHeading}>
            <h1 className={styles.formTitle}>Create Account</h1>
            <p className={styles.formSubTitle}>
              Kindly enter your email and password to access account
            </p>
          </div>

          <form className={styles.authForm} onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <label className={styles.formFieldLabel} htmlFor="name">
                Full Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className={styles.formInputField}
                placeholder="Enter your full name"
                value={userData.name}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label className={styles.formFieldLabel} htmlFor="email">
                Email Address
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className={styles.formInputField}
                placeholder="Enter your email address"
                value={userData.email}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label className={styles.formFieldLabel} htmlFor="phone_no">
                Phone Number
              </label>
              <input
                type="tel"
                name="phone_no"
                id="phone_no"
                className={styles.formInputField}
                placeholder="Enter your phone number"
                inputMode="numeric"
                pattern="[0-9]{11}"
                value={userData.phone_no}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label className={styles.formFieldLabel} htmlFor="password">
                Password
              </label>
              <input
                type="password"
                name="password"
                id="password"
                className={styles.formInputField}
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                placeholder="Enter your password"
                value={userData.password}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label
                className={styles.formFieldLabel}
                htmlFor="confirmPassword"
              >
                Confirm Password
              </label>
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                className={styles.formInputField}
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                placeholder="Enter your password"
                value={userData.confirmPassword}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label className={styles.formFieldLabel} htmlFor="address">
                Address
              </label>
              <input
                type="text"
                name="address"
                id="address"
                className={styles.formInputField}
                placeholder="Enter your addres"
                value={userData.address}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className={styles.formSubmit}>
              <button className={styles.submitBtn}>Sign Up</button>

              <small className={styles.formRedirectText}>
                Already have an account?
                <span>
                  <Link to={"/login"} className={styles.formRedirect}>
                    Sign In{" "}
                    {isLoading && (
                      <Oval
                        color="#FD9F28"
                        height={20}
                        width={20}
                        strokeWidth={5}
                      />
                    )}
                  </Link>
                </span>
              </small>
            </div>
          </form>
        </div>

        <div className={styles.imgContainer}>
          <img
            className={styles.authImage}
            src={AuthImage}
            alt="smiling customers"
          />

          <article className={styles.miniTestimonials}>
            <blockquote className={styles.blockQuote}>
              <p>
                &ldquo;So Fresh is really what it says it is, &ldquo;So
                Fresh&rdquo;. I have never received an order that isnt fresh.
                Great customer service also&rdquo;
              </p>
            </blockquote>

            <div className={styles.ratingControls}>
              <div className={styles.testimonialCredits}>
                <p className={styles.testimonialName}>Remilekun Salami</p>
                <div className={styles.ratings}>
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
              </div>

              <div className={styles.controls}>
                <FaArrowLeft className={styles.controlIcon} />
                <FaArrowRight className={styles.controlIcon} />
              </div>
            </div>
          </article>
        </div>
      </section>
    </main>
  );
};

export default SignUp;
