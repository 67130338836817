import { ToastContainer, toast as tast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ucfirst } from "../../../lib/utlis/helper";

const toastConfig = {
  hideProgressBar: false,
  duration: 3000,
  pauseOnHover: false,
};

interface MessageProps {
  message: string;
}

export const Success = ({ message }: MessageProps): JSX.Element => (
  <div>
    <span style={{ fontSize: 14 }}>{ucfirst(message)}</span>
  </div>
);

export const Error = ({ message }: MessageProps): JSX.Element => (
  <div>
    <span style={{ fontSize: 14 }}>{ucfirst(message)}</span>
  </div>
);

export const Info = ({ message }: MessageProps): JSX.Element => (
  <div>
    <span style={{ fontSize: 14 }}>{ucfirst(message)}</span>
  </div>
);

const Toast = () => {
  return (
    <ToastContainer
      autoClose={3000}
      pauseOnHover={true}
      position="top-center"
      transition={Slide}
    />
  );
};

interface toastConfigProps {
  [key: string]: any;
}
interface toastProps {
  success: (message: string, otherConfig?: toastConfigProps) => void;
  error: (message: string, otherConfig?: toastConfigProps) => void;
  info: (message: string, otherConfig?: toastConfigProps) => void;
}

export const toast: toastProps = {
  success: (message: string, otherConfig?: toastConfigProps) => {
    tast.success(<Success message={message} />, {
      ...toastConfig,
      ...otherConfig,
    });
  },
  info: (message: string, otherConfig?: toastConfigProps) => {
    tast.info(<Info message={message} />, {
      ...toastConfig,
      ...otherConfig,
    });
  },
  error: (message: string, otherConfig?: toastConfigProps) => {
    tast.error(<Error message={message} />, {
      ...toastConfig,
      ...otherConfig,
    });
  },
};

export default Toast;
