// REACT & DEFAULTS
import useOnclickOutside from "react-cool-onclickoutside";
// import { useState } from 'react'
// import usePlacesAutocomplete, {
//   getGeocode,
//   getLatLng,
// } from 'use-places-autocomplete'
// import usePlacesAutocomplete from 'use-places-autocomplete'

// STYLES
import styles from "./PlacesAutocomplete.module.scss";

const PlacesAutocomplete = ({
  handleLocationSelect,
  ready,
  value,
  status,
  data,
  clearSuggestions,
  setValue,
}) => {
  // const [latLng, setLatLng] = useState(null)
  // console.log('📍LatLng Coordinates: ', latLng)
  // const {
  //   ready,
  //   value,
  //   suggestions: { status, data },
  //   setValue,
  //   clearSuggestions,
  // } = usePlacesAutocomplete({
  //   // callbackName: 'YOUR_CALLBACK_NAME',
  //   // requestOptions: {
  //   //   region: 'NG',
  //   //   /* Define search scope here */
  //   // },
  //   debounce: 150,
  // })

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component,
    // dismiss the searched suggestions
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  // const handleLocationSelect =
  //   ({ description }) =>
  //   () => {
  //     // When user selects a place, we can replace the keyword without request data from API
  //     // by setting the second parameter to "false"
  //     setValue(description, false)
  //     clearSuggestions()

  //     // Get latitude and longitude via utility functions
  //     getGeocode({ address: description }).then(results => {
  //       const { lat, lng } = getLatLng(results[0])
  //       setLatLng({ lat, lng })
  //       console.log('📍 Coordinates: ', { lat, lng })
  //     })
  //   }

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        // description,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          className={styles.placesListItem}
          key={place_id}
          onClick={handleLocationSelect(suggestion)}
        >
          {/* <span>{description}</span> */}
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div ref={ref} className={styles.placesContainer}>
      <label htmlFor="deliveryLocation" className={styles.placesLabel}>
        Delivery Location
      </label>
      <input
        type="text"
        name="deliveryLocation"
        id="deliveryLocation"
        className={styles.placesInput}
        placeholder="Where are you going?"
        value={value}
        onChange={handleInput}
        disabled={!ready}
      />
      {/* Render the dropdown if Status is OK */}
      {status === "OK" && (
        <ul className={styles.placesList}>{renderSuggestions()}</ul>
      )}
    </div>
  );
};

export default PlacesAutocomplete;
