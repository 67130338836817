import { ChangeEvent, FormEvent, useState } from "react";
import { formatToNigerianNaira } from "../../../lib/utlis/helper";
import { BsArrowUpRightSquareFill } from "react-icons/bs";
import { TiStarOutline, TiStarFullOutline } from "react-icons/ti";
import Modal from "../Modal";
import Logo from "../../../assests/images/soFreshLogoBig.png";
import styles from "./SingleOrderDetailsCard.module.scss";
import { ReviewData, addReview } from "../../../lib/handlers/reviewHandler";
import { useMutation } from "@tanstack/react-query";
import { useCookies } from "react-cookie";
import { toast } from "../Toast";

type SingleOrderDetailsCardProps = {
  imageUrl: string;
  title: string;
  storeID?: string;
  plan_type_id?: string;
  product_id?: string;
  details: any;
  quantity: number;
  price: string | number;
};

type HandleOpenReviewModalProps = {
  itemID: string;
  store_ID: string;
};

type AddReviewResponse = {
  status: boolean;
  message: string;
  data: any;
};

const SingleOrderDetailsCard = ({
  imageUrl,
  storeID,
  product_id,
  plan_type_id,
  title,
  details,
  quantity,
  price,
}: SingleOrderDetailsCardProps) => {
  const [cookies] = useCookies<string>(["sofresh-token"]);

  const detailsData =
    typeof details === "object"
      ? details.map((item: any, index: number) =>
          index === details.length - 1 ? `${item.name}.` : `${item.name}, `,
        )
      : `${details} Day(s) Plan`;

  const [productStoreID, setProductStoreID] = useState("");
  const [productID, setProductID] = useState("");
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);

  const itemID: string = plan_type_id || product_id || "";
  const store_ID: string = storeID ?? "";

  const handleOpenReviewModal = ({
    itemID,
    store_ID,
  }: HandleOpenReviewModalProps) => {
    setProductID(itemID);
    setProductStoreID(store_ID);
    setIsReviewModalOpen(true);
  };

  const handleCloseReviewModal = () => {
    setIsReviewModalOpen(false);
  };

  // STARRS
  const [rating, setRating] = useState(0); // Initial rating is 0
  const [stars, setStars] = useState<Array<JSX.Element>>(
    Array(5).fill(
      <TiStarOutline key={0} className="text-2xl text-[#339E35]" />,
    ),
  );

  const handleStarClick = (index: number) => {
    const newStars = stars.map((star, i) => {
      if (i < index) {
        setRating(index);
        return (
          <TiStarFullOutline key={i} className="text-2xl text-[#339E35]" />
        );
      } else if (i === index) {
        if (star.type === TiStarOutline) {
          setRating(index + 1);
          return (
            <TiStarFullOutline key={i} className="text-2xl text-[#339E35]" />
          );
        } else {
          setRating(index + 1);
          return (
            <TiStarFullOutline key={i} className="text-2xl text-[#339E35]" />
          );
        }
      } else {
        setRating(index + 1);
        return <TiStarOutline key={i} className="text-2xl text-[#339E35]" />;
      }
    });

    setStars(newStars);
  };

  const [reviews, setReviews] = useState("");
  const handleChangeReview = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setReviews(event.target.value);
  };

  // HANDLE REVIEW DATA
  const newReview: ReviewData = {
    comment: reviews,
    rating: rating,
    product_id: product_id ? productID : "",
    plan_type_id: plan_type_id ? productID : "",
    store_id: storeID ? productStoreID : "",
  };

  // HANDLE MUTATAE FORM DATA
  const { mutate: addReviewMutation } = useMutation(
    () =>
      addReview({
        token: cookies["sofresh-token"],
        data: { ...newReview },
      }),
    {
      onSuccess: async (res: AddReviewResponse) => {
        const { message, status } = res;

        if (message && status === true) {
          toast.success(message);

          // CLEAR DATA ON SUCCESS
          setStars(
            Array(5).fill(
              <TiStarOutline key={0} className="text-2xl text-[#339E35]" />,
            ),
          );
          setRating(0);
          setReviews("");
        }
      },
      onError: (e: Error) => {
        console.error({ e });
        toast.error("An error occured, please try again!");
      },
    },
  );

  const handleSubmitReview = (event: FormEvent) => {
    event.preventDefault();
    // console.log('Submitting newReview: ', newReview)
    addReviewMutation();

    handleCloseReviewModal();
  };

  return (
    <>
      <figure className={styles.singleOrderDetailsCard}>
        <div className={styles.cardImageContainer}>
          <img
            className={styles.singleOrderDetailsCardImage}
            src={imageUrl}
            alt="ordered item"
          />
        </div>

        <figcaption className={styles.singleOrderDetailsCardDetailsContainer}>
          <div className={styles.singleOrderDetailsCardDetails}>
            <h3 className={styles.singleOrderDetailsCardTitle}>{title}</h3>
            <p className={styles.singleOrderDetailsCardDescription}>
              {detailsData}
            </p>

            <div
              className={`w-full flex items-center justify-start gap-8 mt-[10px]`}
            >
              <p className={`styles.singleOrderDetailsCardQuantity`}>
                {`${quantity} ${quantity <= 1 ? "Pack" : "Packs"}`}
              </p>
              <button
                className="bg-[#339E351A] text-[#339E35] text-xs px-2 py-1 font-medium rounded w-max flex items-center justify-start gap-2 hover:bg-[#339E35] hover:text-white transition duration-300"
                onClick={() => handleOpenReviewModal({ itemID, store_ID })}
              >
                <span>Leave a Review</span>
                <span>
                  <BsArrowUpRightSquareFill className="text-base" />
                </span>
              </button>
            </div>
          </div>

          <div className={styles.singleOrderDetailsCardPriceContainer}>
            <h4 className={styles.singleOrderDetailsCardPrice}>
              {formatToNigerianNaira(price)}
            </h4>
          </div>
        </figcaption>
      </figure>

      {isReviewModalOpen && (
        <Modal
          isOpen={isReviewModalOpen}
          onClose={handleCloseReviewModal}
          width={`max-w-[450px]`}
          displayCloseIcon
          modalClassName={`bg-[#FFFAF4]`}
        >
          <form
            onSubmit={(event) => handleSubmitReview(event)}
            className={`w-full flex flex-col items-center justify-center ${styles.reviewForm}`}
          >
            <div className="w-ful">
              <img src={Logo} alt="so fresh logo" />
            </div>

            <div className="w-full flex flex-col items-center justify-center gap-2 pt-6">
              <h1 className="text-[#041D05] text-[20px] font-medium leading-[1.1]">
                Thank you for your Order!
              </h1>
              <p className="text-[#041D05] text-[16px] font-normal leading-[1.4]">
                Please leave us a Review
              </p>

              <div className="w-full flex items-center justify-center">
                {stars.map((star, index) => (
                  <button
                    type="button"
                    key={index}
                    onClick={() => handleStarClick(index)}
                    style={{ cursor: "pointer" }}
                  >
                    {star}
                  </button>
                ))}
              </div>
            </div>
            <div className="w-full py-4">
              <textarea
                name="review"
                id="review"
                className="w-full bg-inherit px-4 py-2 rounded-[9px] border-2 border-dashed border-[#C8A881] focus-within:outline-none focus-within:border-solid"
                rows={5}
                value={reviews}
                onChange={handleChangeReview}
                required
              ></textarea>
            </div>

            <button
              type="submit"
              className={`bg-[#FD9F28] text-white hover:bg-[#339E35] rounded-[24px] px-8 py-3 mt-6 transition-all duration-300 ease-in disabled:bg-[#fd9f2880]`}
              disabled={rating === 0 || reviews.trim() === ""}
            >
              Done
            </button>
          </form>
        </Modal>
      )}
    </>
  );
};

export default SingleOrderDetailsCard;
