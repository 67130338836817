import { formatToNigerianNaira } from "../../../lib/utlis/helper";
import Button from "../Button";
import styles from "./Card.module.scss";

const Card = ({
  smallScreensImageUrl,
  bigScreensImageUrl,
  maxWidth,
  width,
  cardBadge,
  cardTitle,
  cardDescription,
  cardCaption,
  discount,
  itemLink,
  btnText,
  brandColor,
  startingAt,
}: ICardData) => {
  return (
    <figure className={styles.card}>
      <div className={styles.cardImageContainer}>
        <div className={styles.cardImage}>
          <picture>
            <source
              srcSet={bigScreensImageUrl}
              media="(min-width:650px)"
              width={maxWidth}
              style={{ aspectRatio: 1 / 1 }}
              className={styles.cardImageItem}
            />
            <img
              src={smallScreensImageUrl}
              alt={cardTitle}
              width={width}
              style={{ aspectRatio: 1 / 1 }}
              className={styles.cardImageItem}
              loading="eager"
            />
          </picture>
        </div>
      </div>

      <figcaption
        className={`${styles.cardContent} ${
          itemLink && btnText ? styles.equalGrids : styles.flexCards
        }`}
      >
        <article className={styles.cardBody}>
          {cardBadge && <small className={styles.cardBadge}>{cardBadge}</small>}
          <h4 className={`${styles.cardTitle} line-clamp-1`}>{cardTitle}</h4>
          <p className={styles.cardDescription}>{cardDescription}</p>
          <p
            className={`${styles.cardCaption} ${
              brandColor ? styles.brandColor : styles.brandAlt
            }`}
          >
            <span className={styles.startingAt}>
              {startingAt ? "From" : ""}
            </span>{" "}
            <>
              <span
                className={discount > 0 ? "line-through pr-4 text-red-600" : ""}
              >
                {formatToNigerianNaira(cardCaption)}
              </span>
              {discount > 0 && <span>{formatToNigerianNaira(discount)}</span>}
            </>
          </p>
        </article>

        {itemLink && btnText && (
          <div className={styles.cardFooter}>
            <Button linkHref={itemLink} linkText={btnText} />
          </div>
        )}
      </figcaption>
    </figure>
  );
};

export default Card;
