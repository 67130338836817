import React, { Dispatch, SetStateAction } from "react";

interface LocationCardProps {
  imageSrc: string;
  name: string;
  address: string;
  services: string;
  phone: string;
  setImage: Dispatch<SetStateAction<string>>;
}

const LocationCard: React.FC<LocationCardProps> = ({
  imageSrc,
  name,
  address,
  services,
  phone,
  setImage,
}) => {
  return (
    <button
      onClick={() => {
        setImage(imageSrc);
      }}
      className="flex flex-col sm:flex-row"
    >
      <div className="w-full md:w-[152px] h-[114px] flex">
        <img
          src={imageSrc}
          alt={name}
          className="w-full object-cover rounded-[7px]"
        />
      </div>

      <div className="ms-3 w-full md:w-1/2">
        <h4 className="text-[#041D05] text-[20px] text-left leading-[25px] font-[700] mb-2">
          {name}
        </h4>
        <h3 className="text-[#041D05] text-[14px] text-left leading-[19px] font-[500] mb-1 line-clamp-2 text-ellipsis">
          {address}
        </h3>
        <p className="text-[#041D05] text-[12px] text-left leading-[16px] font-[400] mb-2">
          {services}
        </p>
        <p className="text-[#041D05] text-[12px] text-left leading-[16px] font-[400]">
          {phone}
        </p>
      </div>
    </button>
  );
};

export default LocationCard;
