// REACT DEFAULTS
import { useState, ChangeEvent, FormEvent } from "react";
import jsonp from "jsonp";
// import { useMutation } from '@tanstack/react-query'
// import { Link } from 'react-router-dom'

// // COMPONENTS, IMAGES & ICONS
import { toast } from "../../../common/Toast";
// import { subscribeToNewsletter } from '../../../../lib/handlers/newsletterHandler'
// import Playstore from '../../../../assests/images/HomePage/Play-Store.png'
// import Appstore from '../../../../assests/images/HomePage/App-Store.png'

// STYLES
import styles from "./HomeSubscription.module.scss";

type SubscriptionResponse = {
  msg: string;
  result: string;
};

const HomeSubscription = () => {
  const [subscriptionEmail, setSubscriptionEmail] = useState("");

  const handleSubscriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSubscriptionEmail(e.target.value);
  };

  // HANDLE MUTATAE FORM DATA
  // const { mutate: subscribeToNewsletterMutation } = useMutation(
  //   () =>
  //     subscribeToNewsletter({
  //       data: {
  //         email: subscription,
  //       },
  //     }),
  //   {
  //     onSuccess: async (res: any) => {
  //       const { message, status } = res;

  //       if (message !== "" && status) {
  //         toast.success(message);
  //         setSubscription("");
  //       }
  //     },
  //     onError: (e: Error) => {
  //       toast.error("Newsletter Subscription Failed, Please try again.");
  //       console.error({ e });
  //     },
  //   },
  // );

  const handleSubscribeToNewsletter = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // subscribeToNewsletterMutation();
    const url =
      "https://sofreshng.us7.list-manage.com/subscribe/post-json?u=c4859b5fa5283f0e2e678ecab&amp;id=1d3901923d&amp;f_id=00a70fe0f0";
    try {
      jsonp(
        `${url}&EMAIL=${subscriptionEmail}`,
        { param: "c" },
        (_: unknown, data: SubscriptionResponse) => {
          console.info("data", data);
          const { msg } = data;

          if (msg.includes("0 - ")) {
            toast.error("Newsletter Subscription Failed, Please try again.");
            console.error(msg);
          } else {
            toast.success(msg);
            setSubscriptionEmail("");
          }
        },
      );
    } catch (error: unknown) {
      toast.error("Newsletter Subscription Failed, Please try again.");
      console.error({ error });
    }
  };

  return (
    <section className={styles.homeSubscription}>
      <div className={`container ${styles.homeSubscriptionContainer}`}>
        <article className={styles.subscription}>
          <h5 className={`${styles.subscriptionTitle} uppercase`}>
            Get Fresh Updates! 🍃
          </h5>
          <p className={styles.subscriptionText}>
            Sign up for exclusive promos, health tips, new product launches and
            more
          </p>
          <form
            className={styles.subscriptionForm}
            onSubmit={handleSubscribeToNewsletter}
          >
            <label className={styles.subscriptionLabel} htmlFor="subscription">
              <input
                className={styles.subscriptionInput}
                type="email"
                name="subscriptionEmail"
                id="subscriptionEmail"
                placeholder="Enter Email Address"
                value={subscriptionEmail}
                onChange={handleSubscriptionChange}
              />
              <button className={styles.subscriptionBtn} type="submit">
                Subscribe
              </button>
            </label>
          </form>
        </article>

        {/* <article className={styles.download}>
          <h5 className={styles.downloadTitle}>DOWNLOAD APPLICATION</h5>
          <p className={styles.downloadText}>
            Place orders on the go by downloading our <wbr /> mobile
            applications.
          </p>

          <div className={styles.downloadLinks}>
            <Link to={'/'} className={styles.downloadLink}>
              <img src={Playstore} alt='google playstore' />
            </Link>
            <Link to={'/'} className={styles.downloadLink}>
              <img src={Appstore} alt="apple' appstore" />
            </Link>
          </div>
        </article> */}
      </div>
    </section>
  );
};

export default HomeSubscription;
