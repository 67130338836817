import { useState } from "react";

// COMPONENTS & IMAGES
import Modal from "../../../common/Modal";
import Button from "../../../common/Button";
// import HeroBannerImage from '../../../../assests/images/HomePage/heroBanner.png'
import HeroBannerImage from "../../../../assests/images/HomePage/heroBanner__.webp";

// STYLES
import styles from "./HeroBanner.module.scss";
import { SectionSchema } from "../../../../pages/Home";
import { Oval } from "react-loader-spinner";

export interface BannerProps {
  heroSection: SectionSchema | null | undefined;
  isLoading: boolean;
}

const HeroBanner = ({ heroSection, isLoading }: BannerProps) => {
  const [isFulfilmentModalOpen, setIsFulfilmentModalOpen] = useState(false);
  const handleOpenFulfilmentModal = () => {
    setIsFulfilmentModalOpen((prev) => !prev);
  };

  return (
    <>
      <header className={`${styles.homeHeroBanner}`}>
        {isLoading ? (
          <div className="w-full flex items-center justify-center py-[250px]">
            <Oval wrapperClass="ml-2" height={50} width={50} strokeWidth={5} />
          </div>
        ) : (
          <div
            className={`xxl:container px-0 ${styles.homeHeroBannerContainer}`}
          >
            {/* <div className={`${styles.homeHeroImageContainer}`}>
              <img
                className={styles.homeHeroImage}
                src={
                  !isLoading && heroSection?.image_url
                    ? heroSection?.image_url
                    : HeroBannerImage
                }
                alt="hero banner"
                loading="eager"
              />
            </div> */}

            {/* <img
              className={styles.homeHeroImage}
              src={HeroBannerImage}
              alt="hero banner"
              loading="eager"
            /> */}

            <img
              className={styles.homeHeroImage}
              src={
                !isLoading && heroSection?.image_url
                  ? heroSection?.image_url
                  : HeroBannerImage
              }
              alt="hero banner"
              loading="eager"
            />

            <article className={`container ${styles.heading}`}>
              {heroSection?.first_text ? (
                <>
                  <div className={styles.captionOverflow}>
                    <h1
                      className={`${styles.slideUp} ${styles.homeHeroCaption}`}
                    >
                      {heroSection?.first_text}
                    </h1>
                  </div>

                  {heroSection?.second_text && (
                    <div className={styles.captionOverflow}>
                      <h1
                        className={`${styles.slideUp} ${styles.homeHeroCaption}`}
                      >
                        {heroSection?.second_text}
                      </h1>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className={styles.captionOverflow}>
                    <h1
                      className={`${styles.slideUp} ${styles.homeHeroCaption}`}
                    >
                      More Than Food.
                    </h1>
                  </div>
                  <div className={styles.captionOverflow}>
                    <h1
                      className={`${styles.slideUp} ${styles.homeHeroCaption}`}
                    >
                      It's A Lifestyle
                    </h1>
                  </div>
                </>
              )}

              {/* <div className={styles.captionOverflow}>
              <h1
                className={`${styles.slideUp} ${styles.homeHeroCaption} ${styles.lastCaption}`}>
                with Sofresh
              </h1>
            </div> */}

              <div className="mt-4 lg:mt-16">
                <Button
                  // linkHref='https://chowdeck.com/store/tag/so-fresh?page=tags'
                  linkText="Order Now"
                  onClick={handleOpenFulfilmentModal}
                />
                {/* <Button linkHref='/menu' linkText='Order Now' /> */}
              </div>
            </article>
          </div>
        )}
      </header>

      {isFulfilmentModalOpen && (
        <Modal
          isOpen={isFulfilmentModalOpen}
          onClose={() => setIsFulfilmentModalOpen(false)}
          width={`max-w-[500px]`}
          displayCloseIcon
        >
          <div className="w-full flex flex-col items-center justify-center gap-8">
            <h4 className="text-lg">Select Delivery Type</h4>

            <div className="w-full flex flex-col md:flex-row items-center justify-center gap-4 md:gap-8">
              <Button
                linkHref="https://chowdeck.com/store/tag/so-fresh?page=tags"
                linkText="Chowdeck"
                greenBtn
                full
              />

              <Button
                linkHref="https://glovo.go.link/open?link_type=store&brand_id=8453&adjust_t=vj6hcng&adjust_campaign=Web_To_App&adjust_adgroup=Referral_Acquisition_PartnerWeb_All_PartnersVisibility_Eng_Pm01_NG_AllCities_LocalBudget_09072024&adjust_deeplink=glovoapp%3A%2F%2Fopen%3Flink_type%3Dstore%26brand_id%3D8453&adjust_fallback=https%3A%2F%2Fglovoapp.com%2F%3Futm_source%3DWeb_to_app_banner%26utm_medium%3DWeb_To_App%26utm_campaign%3DReferral_Acquisition_PartnerWeb_All_PartnersVisibility_Eng_Pm01_NG_AllCities_LocalBudget_09072024&adj_redirect_macos=https%3A%2F%2Fglovoapp.com%2F%3Futm_source%3DWeb_to_app_banner%26utm_medium%3DWeb_To_App%26utm_campaign%3DReferral_Acquisition_PartnerWeb_All_PartnersVisibility_Eng_Pm01_NG_AllCities_LocalBudget_09072024"
                linkText="Glovo"
                full
              />

              <Button
                linkHref="https://wa.link/4y9ebv"
                linkText="Pick Up"
                brownBtn
                full
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default HeroBanner;
