import Footer from "../../components/layouts/Footer";
import Navbar from "../../components/layouts/NavBar";
import Article from "./Article";
import Aside from "./Aside";

const PrivacyPolicy = () => {
  return (
    <main className="min-h-screen flex flex-col justify-between">
      <Navbar />
      <div className="container flex justify-center gap-8 xl:gap-16 my-6 xl:my-8">
        <Aside />

        <section className="w-full sm:w-3/5 xl:w-2/3 self-start flex flex-col justify-center gap-10 mb-2 xl:mb-0 px-6 sm:px-0 pe-7 xl:pe-0">
          <Article
            title="Introduction"
            paragraphs={[
              `While ordering, making inquiries, registering, subscribing to our
              newsletter, responding to a survey, filling out a form or entering
              information on our site or social media engagement, Personal
              Information such as name, email address, phone number, physical
              address, birthdays, postal address, financial account information,
              such as credit card number, other payment details and other
              information may be collected. Also, if you buy goods at our stores
              or through our sites or otherwise - what you buy, where you buy,
              how frequently you buy and rewards you earn may also be collected.`,
            ]}
          />

          <Article
            title="Content"
            orderedListItems={[
              `How do we use your information?`,
              `How do we protect visitor information?`,
              `Do we use &ldquo;cookies&rdquo;?`,
              `Third-party disclosure`,
              `Third-party links`,
              `What Choices Does So Fresh Offer About Personal Information?`,
              `Questions and Feedback`,
              `Card Payments`,
              `Privacy of Information`,
              `Security`,
              `Credit/Debit Card Payment`,
              `Limits on Use`,
              `Amendments`,
              ` Liability`,
            ]}
          />

          <Article
            title="How do we use your information?"
            paragraphs={[
              `So Fresh may use information about you for purposes described in
              this Policy or disclosed to you on our Sites or with our Services.
              For example, we may use information about you to:`,
            ]}
            unorderedListItems={[
              `Process and manage your purchase and use of So Fresh products
                and services, including your accounts and program participation.`,
              `To personalize your experience and improve how our website
                works, to give you the best products and service based on your
                preferences and expectations.`,
              `Respond swiftly to your customer service requests inquiries,
                post your comments or statements on any blog or other online
                forum maintained on our Sites, or take other actions in response
                to your inquiries or other Site activities.`,
              `Create personalized promotions by combining your personal
                information with non-personal information about you, such as the
                amounts and types of purchases you make or any benefits you
                receive through our programs.`,
              `Communicate with you about your orders or purchases, your
                services, accounts and program participation, a contest or
                sweepstakes you have entered, and your requests for information.`,
              `Communicate with you about our brands, products, events or other
                promotional purposes, including co-branded offers and affiliate
                and partner offers.`,
              `To allow for effective communication and information
                dissemination to you.`,
            ]}
          />

          <Article
            title="How do we protect visitor information?"
            paragraphs={[
              `So Fresh takes reasonable steps to maintain appropriate physical, technical and administrative security to help prevent loss, misuse, unauthorized access, disclosure or modification of personal information.`,
              `While we take these reasonable efforts to safeguard your personal information, no system or transmission of data over the Internet or any other public network can be guaranteed to be 100% secure.`,
              `Our website is scanned on a regular basis for vulnerabilities in order to make your visit to our site secure. Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. All transactions are processed through a gateway provider and are not stored or processed on our servers.`,
            ]}
          />

          <Article
            title="Do we use &ldquo;cookies&rdquo;?"
            paragraphs={[
              `We do not use cookies for tracking purposes.`,
              `You can usually choose to set your browser to warn you when a
              cookie is being sent or to remove or reject cookies. Each browser
              is a little different, so look at your browser Help menu to learn
              the correct way to modify your cookie settings. If you choose to
              remove or reject cookies, it will affect many features or Services
              on our Sites.`,
            ]}
          />

          <Article
            title="Third-party disclosure"
            paragraphs={[
              `We do not sell, trade, or otherwise transfer to outside parties
              your personally identifiable information unless we provide users
              with advance notice.`,
              `We may also share information with companies that provide support
              services to us (such as credit card processors, mailing houses or
              website hosts) and that help us market our products and services
              (such as email vendors). These companies may use information about
              you to perform their functions on our behalf. We may disclose
              specific information (i) upon lawful request, in response to legal
              process, and when required to comply with laws, (ii) to enforce
              our agreements, corporate policies, and terms of use or (iii) to
              protect the rights, property or safety of So Fresh, our employees,
              agents, customers, and others.`,
              `If you participate in any blog or other online forum on our Sites,
              any personal information that you post on our Sites may be shared
              with other forum participants and Site visitors.`,
              `In the event of a merger, acquisition, financing, or sale of
              assets or any other situation involving the transfer of some or
              all of our business assets, Robeks may disclose personal
              information to those involved in the negotiation or transfer.`,
              ` We may also share aggregated or anonymized information that does
              not directly identify you. We may also release information when
              it&apos;s release is appropriate to comply with the law, enforce
              our site policies, or protect ours or others&apos; rights,
              property, or safety. However, non-personally identifiable visitor
              information may be provided to other parties for marketing,
              advertising, or other uses.`,
            ]}
          />

          <Article
            title="Third-party links"
            paragraphs={[
              `Occasionally, at our discretion, we may include or offer
              third-party products or services on our website. These third-party
              sites have separate and independent privacy policies. We therefore
              have no responsibility or liability for the content and activities
              of these linked sites. Nonetheless, we seek to protect the
              integrity of our customers.`,
            ]}
          />

          <Article
            title="What Choices Does So Fresh Offer About Personal Information?"
            paragraphs={[
              `So Fresh offers choices for you to request to update or change
              your personal information and how we communicate with you. Here
              are some of the ways you can request changes:`,
              `Follow the opt-out instructions in promotional emails we send you.`,
              `If you opt out of receiving promotional communications from us, we
              may still send you non-promotional communications such as emails
              about your accounts or our ongoing business relations.`,
            ]}
          />

          <Article
            title="Questions and Feedback"
            paragraphs={[
              `We welcome your questions, comments and concerns about privacy.`,
            ]}
          />

          <Article
            title="Card Payments"
            paragraphs={[
              `This Web site is available 24 hours a day, 7 days a week and it
              allows you to make electronic payments, including payments by
              debit cards. We do not violate the privacy of cardholders who
              transact on our site`,
            ]}
          />

          <Article
            title="Privacy of Information"
            paragraphs={[
              `By agreeing to these terms and conditions, you acknowledge that
              the City will receive certain personal information when you pay
              with your debit card, and that you are voluntarily providing this
              information to us. So Fresh will not rent, sell or otherwise make
              available to any third party any information that identifies you
              or payments you make through the Web site, other than to process
              the transaction requested by you or comply with applicable law,
              rule or regulation.`,
            ]}
          />

          <Article
            title="Security"
            paragraphs={[
              `To process your payment securely, we use SSL? Secure Sockets Layer
              , a security protocol that provides data encryption, server
              authentication, and message integrity for connections to the
              Internet, to ensure that account numbers and personal data you
              provide by using this Web site are not transmitted over the
              Internet unencrypted and cannot be viewed by unauthorized
              individuals.`,
            ]}
          />

          <Article
            title="Credit/Debit Card Payment"
            paragraphs={[
              `The Credit Card and Debit Card payment service is an
              Internet-based service that allows you to make payments to the So
              Fresh via the World Wide Web. In activating this service you
              authorize the City or its authorized agent to debit or charge your
              account which allows your payment to be forwarded to us, based on
              the payment instructions you provide on the Web site, subject to
              these Terms and Conditions.`,
            ]}
          />

          <Article
            title="Limits on Use"
            paragraphs={[
              `You must be of legal age to use our Web site. Payment must be made
              in Naira. You agree not to make a payment on our site that exceeds
              the funds or available credit line in your Account. If you do, we
              have no obligation to complete the transaction and you agree that
              we may debit your Account for our returned item fee or other
              similar fee. Your use of your Account may be limited by your
              agreement with your Financial Institution and by applicable law.
              We are not responsible for not completing a transaction as a
              result of any such limit on your Account, and we are not
              responsible if your Financial Institution fails to honour any
              debit against your Account. At our sole discretion, we may refuse
              to complete any transaction, which we have reason to believe is
              not made by you, may violate any law, rule or regulation, or if we
              have reasonable cause not to honor it. You may not authorize us in
              advance to make future payments, or payments that are expected to
              recur at regular intervals.`,
            ]}
          />

          <Article
            title="Amendments"
            paragraphs={[
              `We may amend these Terms and Conditions from time to time. In such
              event, we will post the amended terms on the Web site. If you use
              the Web site after we post such a change on our Web site, it will
              constitute your agreement to the revised terms. You should
              terminate the use of this Web site if you do not agree with any
              such amendment.`,
            ]}
          />

          <Article
            title="Liability"
            paragraphs={[
              `You shall remain liable for all transactions you initiated through
              this Web site, and the payment of all amounts you owe. You agree
              to pay all costs and expenses that we may incur in order to
              collect any amounts you owe under these Terms and Conditions. An
              agent of a person in whose name an Account has been established
              warrants that it is duly authorized to use this service on behalf
              of the principal and that to the best of his or her knowledge and
              information the representations made on behalf of the principal
              are true and correct.`,
            ]}
          />
        </section>
      </div>
      <Footer />
    </main>
  );
};

export default PrivacyPolicy;
