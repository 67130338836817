import { axiosClient } from "../utlis/axiosClient";

export const getStores = async () => {
  try {
    const { data } = await axiosClient.get(`/public/stores?page_size=5000`);
    return data;
  } catch (error: any) {
    console.error({ error });

    throw new Error("An error occured. Please try again.");
  }
};

export const getSingleStore = async ({
  slug,
}: {
  slug: string | undefined;
}) => {
  try {
    const { data } = await axiosClient.get(`/public/stores/${slug}`);
    return data.data;
  } catch (error: any) {
    console.error({ error });
  }
};

export const getProductCategoriesInStore = async ({
  slug,
}: {
  slug: string | undefined;
}) => {
  try {
    const { data } = await axiosClient.get(`/public/stores/${slug}/categories`);
    return data;
  } catch (error: any) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

// public/stores/:slug/products
export const getProductsInStore = async ({
  slug,
}: {
  slug: string | undefined;
}) => {
  try {
    const { data } = await axiosClient.get(`/public/stores/${slug}/products`);
    return data;
  } catch (error: any) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};
