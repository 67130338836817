// COMPONENTS
import AllCategoriesCarousel from "../AllCategoriesCarousel";
import SectionHeader from "../SectionHeader";

// STYLES
import styles from "./HomeCarousel.module.scss";

const HomeCarousel = () => {
  return (
    <section className={`${styles.homeCategoriesSection} container`}>
      <SectionHeader
        title="Explore Our"
        caption="Varieties"
        linkText="Our Menu"
        LinkHref="https://chowdeck.com/store/tag/so-fresh?page=tags"
        // LinkHref='/menu'
      />

      <AllCategoriesCarousel />
    </section>
  );
};

export default HomeCarousel;
