import { useState, ReactNode, createContext, FC, useEffect } from "react";
import { useCookies } from "react-cookie";

type TAuthState = {
  user: any;
  isAuthenticated: boolean;
  handleAuthState: (data: any) => void;
  token: string | null;
};

export const AuthContext = createContext<TAuthState>({
  user: null,
  isAuthenticated: false,
  handleAuthState: (data: any) => {},
  token: null,
});

type TAppContextProps = {
  children: ReactNode;
};

const AppContextProvider: FC<TAppContextProps> = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    "sofresh-token",
    "sofresh-user",
  ]);

  const [state, setState] = useState({
    isAuthenticated: false,
    user: null,
    token: null,
  });

  useEffect(() => {
    const token = cookies["sofresh-token"];
    const user = cookies["sofresh-user"];
    if (token && user) {
      setState({
        isAuthenticated: true,
        user: user,
        token,
      });
    }
  }, [cookies]);

  const handleAuthState = (data: any) => {
    if (data) {
      setState({
        isAuthenticated: true,
        user: data.user,
        token: data.token,
      });
      setCookie("sofresh-token", data.token, {
        secure: true,
        sameSite: "none",
      });
      setCookie("sofresh-user", data.user, { secure: true, sameSite: "none" });
      return;
    } else {
      setState({
        isAuthenticated: false,
        user: null,
        token: null,
      });
      removeCookie("sofresh-token", { secure: true, sameSite: "none" });
      removeCookie("sofresh-user", { secure: true, sameSite: "none" });
    }
  };

  return (
    <AuthContext.Provider value={{ ...state, handleAuthState }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AppContextProvider;

// import { useState, ReactNode, createContext, FC } from 'react'
// import { useCookies } from 'react-cookie'

// type TAuthState = {
//   user: any
//   isAuthenticated: boolean
//   handleAuthState: (data: any) => void
//   token: string | null
// }

// export const AuthContext = createContext<TAuthState>({
//   user: null,
//   isAuthenticated: false,
//   handleAuthState: (data: any) => {},
//   token: null,
// })

// type TAppContextProps = {
//   children: ReactNode
// }

// const AppContext: FC<TAppContextProps> = ({ children }) => {
//   const [cookies, setCookie, removeCookie] = useCookies([
//     'sofresh-token',
//     'sofresh-user',
//   ])

//   const [state, setState] = useState({
//     isAuthenticated: false,
//     user: null,
//     token: null,
//   })

//   const handleAuthState = (data: any) => {
//     if (data) {
//       setState({
//         isAuthenticated: true,
//         user: data.user,
//         token: data.token,
//       })
//       if (!cookies['sofresh-token']) {
//         setCookie('sofresh-token', data.token)
//         setCookie('sofresh-user', data.user)
//       }
//       return
//     } else {
//       setState({
//         isAuthenticated: false,
//         user: null,
//         token: null,
//       })
//       removeCookie('sofresh-token')
//       removeCookie('sofresh-user')
//     }
//   }

//   return (
//     <AuthContext.Provider value={{ ...state, handleAuthState }}>
//       {children}
//     </AuthContext.Provider>
//   )
// }

// export default AppContext
