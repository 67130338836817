// HOME CUSTOMERs FAVOURITE SECTION
import FaveShrimp from "../../../assests/images/HomePage/fave-shrimp.webp";
import FaveParfait from "../../../assests/images/HomePage/fave-parfait.webp";
import FaveSandwich from "../../../assests/images/HomePage/fave-sandwich.webp";
import FaveTwist from "../../../assests/images/HomePage/fave-twist.webp";

// HOME BLOG SECTION
import BlogOne from "../../../assests/images/HomePage/blogOne.webp";
import BlogTwo from "../../../assests/images/HomePage/blogTwo.webp";
import BlogThree from "../../../assests/images/HomePage/blogThree.webp";

export const customerFave: ICustomerFave[] = [
  {
    category: "Salads",
    imgUrl: FaveShrimp,
    name: "Mini - Spicy Shrimp Avocado salad",
    slug: "Spicy shrimp, Avocado, Tomatoes & Eggs, Cucumber, Carrot and Lettuce",
    price: 2600,
    linkHref: "menu",
  },
  {
    category: "Parfait & Fruit Packs",
    imgUrl: FaveParfait,
    name: "Pineapple parfait with coconut flakes (mini)",
    slug: "Berries, Grapes, Pineapple, roasted coconut flakes in Low Fat yoghurt",
    price: 1400,
    linkHref: "menu",
  },
  {
    category: "Sandwich & Wraps",
    imgUrl: FaveSandwich,
    name: "Chicken & avo sandwich ",
    slug: "Lettuce, spicy chicken, avocado & tomatoes",
    price: 2600,
    linkHref: "menu",
  },
  {
    category: "Juices & Smoothies",
    imgUrl: FaveTwist,
    name: "Delightful twist",
    slug: "Pineapple, Apple, Orange and Lime",
    price: 1600,
    linkHref: "menu",
  },
  {
    category: "Salads2",
    imgUrl: FaveShrimp,
    name: "Mini - Spicy Shrimp Avocado salad",
    slug: "Spicy shrimp, Avocado, Tomatoes & Eggs, Cucumber, Carrot and Lettuce",
    price: 2600,
    linkHref: "menu",
  },
  {
    category: "Parfait & Fruit Packs2",
    imgUrl: FaveParfait,
    name: "Pineapple parfait with coconut flakes (mini)",
    slug: "Berries, Grapes, Pineapple, roasted coconut flakes in Low Fat yoghurt",
    price: 1400,
    linkHref: "menu",
  },
  {
    category: "Sandwich & Wraps2",
    imgUrl: FaveSandwich,
    name: "Chicken & avo sandwich ",
    slug: "Lettuce, spicy chicken, avocado & tomatoes",
    price: 2600,
    linkHref: "menu",
  },
  {
    category: "Juices & Smoothies2",
    imgUrl: FaveTwist,
    name: "Delightful twist",
    slug: "Pineapple, Apple, Orange and Lime",
    price: 1600,
    linkHref: "menu",
  },
];

export const BLOG_DATA: IBlogData[] = [
  {
    blogImgUrl: BlogOne,
    blogTitle:
      "Join me and over 2,000 people on the 10k Steps Daily Challenge with So Fresh",
    blogText:
      "I am Olagoke Balogun, CEO of So Fresh, the healthy food brand you love. This year at So Fresh, we are on a mission to serve you.",
    blogDate: "25th February, 2022",
    blogLinkHref: "blogs",
  },
  {
    blogImgUrl: BlogTwo,
    blogTitle:
      "Join me and over 2,000 people on the 10k Steps Daily Challenge with So Fresh",
    blogText:
      "I am Olagoke Balogun, CEO of So Fresh, the healthy food brand you love. This year at So Fresh, we are on a mission to serve you.",
    blogDate: "25th February, 2022",
    blogLinkHref: "blogs",
  },
  {
    blogImgUrl: BlogThree,
    blogTitle:
      "Join me and over 2,000 people on the 10k Steps Daily Challenge with So Fresh",
    blogText:
      "I am Olagoke Balogun, CEO of So Fresh, the healthy food brand you love. This year at So Fresh, we are on a mission to serve you.",
    blogDate: "25th February, 2022",
    blogLinkHref: "blogs",
  },
];
