// REACT DEFAULTS
import { useRef } from "react";
import { useQuery } from "@tanstack/react-query";

// COMPONENTS
import Carousel from "react-elastic-carousel";
import Card from "../../../common/Card";

// LIBs & UTILs
import { getProductCategories } from "../../../../lib/handlers/productHandler";

// IMAGES & ICONS
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Oval } from "react-loader-spinner";

// STYLES
import styles from "./AllCategoriesCarousel.module.scss";
import { sortFn } from "../../../../lib/utlis/helper";

const AllCategoriesCarousel = () => {
  const carouselRef = useRef(null);

  // GET PRODUCT CATEGORY DATA
  const {
    data: productCatData,
    isLoading: loadingProductCategoryData,
    isSuccess,
  } = useQuery(["product-categories"], () => getProductCategories());

  // SORT productCategoryData in ORDER OF LATEST
  let productCategoryData;
  if (isSuccess) {
    productCategoryData = productCatData.sort(sortFn);
  }
  // console.log('PRODUCTCATEGORYDATA: ', productCategoryData)

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 425, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1020, itemsToShow: 4 },
    { width: 1150, itemsToShow: 4 },
  ];

  const handlePrevClick = () => {
    carouselRef.current?.slidePrev();
  };

  const handleNextClick = () => {
    carouselRef.current?.slideNext();
  };
  return (
    <section className={styles.categoriesCarousel}>
      <div className={styles.carouselOuter}>
        <button
          onClick={handlePrevClick}
          className={`${styles.carouselControls} ${styles.carouselPrev}`}
        >
          <FaArrowLeft />
        </button>

        <Carousel
          ref={carouselRef}
          enableAutoPlay
          autoPlaySpeed={3500}
          easing="cubic-bezier(1,.15,.55,1.5)"
          transitionMs={350}
          enableMouseSwipe
          breakPoints={breakPoints}
          onChange={(currentItem) =>
            currentItem.item.className.includes("lastCarouselItem") &&
            setTimeout(() => {
              carouselRef?.current?.goTo(0);
            }, 7000)
          }
          className={styles.carouselInner}
        >
          {loadingProductCategoryData ? (
            <div className="w-full flex align-middle justify-center my-32">
              <Oval
                wrapperClass="ml-2"
                height={50}
                width={50}
                strokeWidth={5}
              />
            </div>
          ) : (
            productCategoryData?.sort(sortFn)?.map((productCategory, index) => (
              <Card
                key={productCategory.id}
                maxWidth={"100%"}
                width={"300px"}
                bigScreensImageUrl={productCategory.image}
                smallScreensImageUrl={productCategory.image}
                cardTitle={productCategory.name}
                cardCaption={productCategory.minimum_price}
                brandColor
                startingAt
                /* YOU MUST INCLUDE THIS CLASSNAME TO RESTART THE CAROUSEL AUTO_SCROLL */
                className={`
                ${
                  index === productCategoryData.length - 4
                    ? "lastCarouselItem"
                    : ""
                }`}
              />
            ))
          )}
        </Carousel>

        <button
          onClick={handleNextClick}
          className={`${styles.carouselControls} ${styles.carouselNext}`}
        >
          <FaArrowRight />
        </button>
      </div>
    </section>
  );
};

export default AllCategoriesCarousel;
