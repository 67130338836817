// COMPONENTS, IMAGES & ICONS
import Button from "../../../common/Button";
import AboutImage from "../../../../assests/images/HomePage/aboutBannerImage.webp";

// STYLES
import styles from "./HomeAboutSection.module.scss";
import { BannerProps } from "../HeroBanner";
import { Oval } from "react-loader-spinner";

const HomeAboutSection = ({ heroSection, isLoading }: BannerProps) => {
  return (
    <section className={styles.homeAboutSection}>
      {isLoading ? (
        <div className="w-full flex items-center justify-center py-[250px]">
          <Oval wrapperClass="ml-2" height={50} width={50} strokeWidth={5} />
        </div>
      ) : (
        <div className={`container ${styles.homeAboutSection}`}>
          <article className={`${styles.aboutSectionCaptionContainer}`}>
            <div className={styles.aboutSectionCaption}>
              {heroSection?.text ? (
                <h2 className={styles.aboutSectionCaptionTitle}>
                  {heroSection?.text}
                </h2>
              ) : (
                <h2 className={styles.aboutSectionCaptionTitle}>
                  The So Fresh Story
                </h2>
              )}

              {heroSection?.sub_text ? (
                <p className={styles.aboutSectionCaptionText}>
                  {heroSection?.sub_text}
                </p>
              ) : (
                <p className={styles.aboutSectionCaptionText}>
                  SoFresh is Nigeria&apos;s pioneer and number one healthy food
                  chain, providing fresh, delicious, nutrient-rich healthy meals
                  and committed to promoting a healthy lifestyle. FRESH IS BEST
                  and that is how we win every day
                  <span className={styles.displaySmNone}>
                    , selling a wide range of fresh salads, juices, smoothies,
                    parfait, fiesta wraps, sandwich and other quick on the go
                    healthy meals
                  </span>
                  .
                </p>
              )}

              <Button linkHref="/about" linkText="Read More" lightBtn />
            </div>
          </article>

          <div className={styles.aboutSectionImageContainer}>
            <img
              className={styles.aboutSectionImage}
              src={
                !isLoading && heroSection?.image_url
                  ? heroSection?.image_url
                  : AboutImage
              }
              alt="about us section"
              loading="eager"
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default HomeAboutSection;
