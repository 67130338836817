import { axiosClient } from "../utlis/axiosClient";

interface OrderData {
  // TODOs
  // Define the shape of your data object here
}

/*==============================================
=              ORDER HISTORY BLOCK             =
==============================================*/
export const getCompletedOrderHistory = async (token: string) => {
  try {
    const response = await axiosClient.get(
      `user/orders?order_status=completed`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data.data;
  } catch (error) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

export const getPendingOrderHistory = async (token: string) => {
  try {
    const response = await axiosClient.get(`user/orders?order_status=pending`, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

export const getSingleOrder = async ({
  token,
  reference,
}: {
  token: string;
  reference: any;
}) => {
  try {
    const response = await axiosClient.get(`user/orders/${reference}`, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error: any) {
    console.error({ error });
  }
};

export const getSinglePlanType = async ({
  planType,
  slug,
}: {
  planType: any;
  slug: any;
}) => {
  try {
    const response = await axiosClient.get(
      `public${planType}/plan-type?slug=${slug}`,
    );
    return response.data.data;
  } catch (error: any) {
    console.error({ error });
  }
};
/*======    End of ORDERHISTORY BLOCK    =======*/

/*=============================================
=              CREATE ORDER BLOCK             =
=============================================*/
export const createOrder = async ({
  token,
  data,
}: {
  token: string;
  data: OrderData;
}): Promise<OrderData> => {
  try {
    const response = await axiosClient.post(`/user/orders`, data, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    console.info({ response });
    return response.data;
  } catch (error) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};
/*=====    End of CREATE ORDER BLOCK    ======*/

/*=============================================
=            GET DELIVERY PRICE              =
=============================================*/

export const getDeliveryFee = async ({
  lng,
  lat,
}: {
  lng: number;
  lat: number;
}) => {
  // export const getDeliveryFee = async (token: string, {lng,lat}:{lng:number,lat:number}) => {
  try {
    const response = await axiosClient.get(
      `/public/delivery-point?latitude=${lng}&longitude=${lat}`,
      {
        headers: {
          "Content-Type": "application/json",
          // authorization: `Bearer ${token}`,
        },
      },
    );

    // console.log('DELIVERY DETAILS: ', response.data)
    return response.data;
  } catch (error) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};

/*=====  End of GET DELIVERY PRICE    ======*/
