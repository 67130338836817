// REACT & DEFAULTS
// import { useState } from 'react'

// COMPONENTS, UTILS& EXTERNAL LIBs
import Layout from "../../components/layouts/Layout";
import HeroBanner from "../../components/layouts/Home/HeroBanner";
import HomeCarousel from "../../components/layouts/Home/HomeCarousel";
import SuperComboBanner from "../../components/layouts/Home/SuperComboBanner";
// import CustomersFavourite from '../../components/layouts/Home/CustomersFavourite'
import HomeBlogCards from "../../components/layouts/Home/HomeBlogCards";
import HomeAboutSection from "../../components/layouts/Home/HomeAboutSection";
import HomeSubscription from "../../components/layouts/Home/HomeSubscription";
// import SelectStoreModal from '../../components/layouts/Home/SelectStoreModal'

// STYLES
import styles from "./Home.module.scss";
import { useQuery } from "@tanstack/react-query";
import { getWebsiteContent } from "../../lib/handlers/websiteContentHandler";
import { useEffect, useState } from "react";

export interface SectionSchema {
  section: string;
  text?: string;
  first_text?: string;
  second_text?: string;
  image_url: string;
  sub_text?: string;
}

interface WebsiteContentSchema {
  contents: SectionSchema[];
  id: string | number;
}

const Home = () => {
  const [heroSection, setHeroSection] = useState<
    SectionSchema | null | undefined
  >(null);

  const [firstSection, setFirstSection] = useState<
    SectionSchema | null | undefined
  >(null);

  const [secondSection, setSecondSection] = useState<
    SectionSchema | null | undefined
  >(null);
  // const isStoreVailable = localStorage.getItem('soFreshStore')

  // const [isSelectStoreModalOpen, setIsSelectStoreModalOpen] = useState(
  //   isStoreVailable ? false : true
  // )
  // const handleCloseSelectStoreModal = () => {
  //   setIsSelectStoreModalOpen(false)
  // }

  const { data: websiteContent, isLoading } = useQuery(
    ["website-content"],
    () => getWebsiteContent<WebsiteContentSchema>()
  );

  useEffect(() => {
    // console.log(websiteContent);
    // setHeroSection(websiteContent?.contents[0]);
    // setFirstSection(websiteContent?.contents[1]);
    // setSecondSection(websiteContent?.contents[2]);

    console.log(websiteContent);
    setHeroSection(null);
    setFirstSection(null);
    setSecondSection(null);
  }, [websiteContent]);
  return (
    <Layout title={"Healthy Food Made for You"} description={"Home Page"}>
      <main className={styles.home}>
        <HeroBanner heroSection={heroSection} isLoading={isLoading} />
        <HomeCarousel />
        <SuperComboBanner heroSection={firstSection} isLoading={isLoading} />
        {/* <CustomersFavourite /> */}
        <HomeBlogCards />
        <HomeAboutSection heroSection={secondSection} isLoading={isLoading} />
        <HomeSubscription />

        {/* {isSelectStoreModalOpen && (
          <SelectStoreModal
            handleCloseSelectStoreModal={handleCloseSelectStoreModal}
            isSelectStoreModalOpen={isSelectStoreModalOpen}
          />
        )} */}
      </main>
    </Layout>
  );
};

export default Home;
