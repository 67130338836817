// REACT & DEFAULTS
import { useNavigate } from "react-router-dom";

// LIBs & UTILs
import { formatToNigerianNaira } from "../../../lib/utlis/helper";

// IMAGES & ICONS
import Time from "../../../assests/images/OrderHistoryPage/time.png";
import Meal from "../../../assests/images/OrderHistoryPage/meal.png";
import Delivery from "../../../assests/images/OrderHistoryPage/delivery.png";

// STYLES
import styles from "./OrderHistoryCard.module.scss";

const OrderHistoryCard = ({
  reference,
  created_at,
  carts_id_length,
  delivery_type,
  total_amount,
}: TOrderHistoryCard) => {
  const navigate = useNavigate();

  const navigateToReference = () => {
    navigate(`/order-history/${reference}`);
  };

  return (
    <article className={styles.orderStatusCard}>
      <div className={styles.orderStatusInfo}>
        <h2 className={styles.orderStatusCaption}>{`Order #${reference}`}</h2>
        <div className={styles.orderStatusDetails}>
          <p className={styles.orderStatusItem}>
            <img className={styles.orderStatusIcon} src={Time} alt="time" />
            <span>{created_at.toString()}</span>
            {/* <span>30 - 40 mins</span> */}
          </p>
          <p className={styles.orderStatusItem}>
            <img className={styles.orderStatusIcon} src={Meal} alt="meal" />
            <span>{`${carts_id_length} Meal Items`}</span>
          </p>
          <p className={styles.orderStatusItem}>
            <img
              className={styles.orderStatusIcon}
              src={Delivery}
              alt="delivery"
            />
            <span>{delivery_type}</span>
          </p>
        </div>
      </div>

      <p className={styles.orderPrice}>{formatToNigerianNaira(total_amount)}</p>

      <div className={styles.moreInfoBtnContainer}>
        <button
          className={`${styles.moreInfoBtn} ${styles.viewDetailsBtn}`}
          onClick={navigateToReference}
        >
          {/* <button className={`${styles.moreInfoBtn} ${styles.viewDetailsBtn}`}> */}
          View Order Details
        </button>

        {/* <button className={`${styles.moreInfoBtn} ${styles.chatWithUsBtn}`}>
          Chat With Us
        </button> */}
      </div>
    </article>
  );
};

export default OrderHistoryCard;
