import React, { useState, ChangeEvent, FormEvent } from "react";
import Button from "../../../common/Button";
import {
  ContactFormData,
  Subject,
  submitContactForm,
} from "../../../../lib/handlers/contactFormHandler";
import { toast } from "../../../common/Toast";
import { useMutation } from "@tanstack/react-query";

const FormContact = () => {
  const initialContactFormData: ContactFormData = {
    name: "",
    email: "",
    phone_no: "",
    subject: Subject.INQUIRY,
    content: "",
  };

  const [formData, setFormData] = useState<ContactFormData>(
    initialContactFormData,
  );

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // HANDLE MUTATAE FORM DATA
  const { mutate: submitContactFormMutation } = useMutation(
    () =>
      submitContactForm({
        data: formData,
      }),
    {
      onSuccess: async (res: any) => {
        const { message, status } = res;

        if (message !== "" && status) {
          toast.success(message);
          setFormData(initialContactFormData);
        }
      },
      onError: (e: Error) => {
        toast.error(e.message);
        toast.error(`${formData.subject} submission Failed, Please try again.`);
        console.error({ e });
      },
    },
  );

  const handleSubmitContactForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // console.info('Form data submitted:', formData)
    submitContactFormMutation();
  };

  return (
    <section className="font-BRFirma !bg-[#FFFAF4] px-[20px] py-[100px]">
      <div className="container">
        <div className="w-full pb-4">
          <h2 className="max-w-[30ch] text-[#041D05] font-[600] text-[40px] leading-[50px]">
            What are you interested in sharing/asking?
          </h2>
        </div>

        <div className="mt-9">
          <form onSubmit={handleSubmitContactForm}>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6">
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block mb-2 text-[14px] leading-[16px] font-[500] text-[#000]"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Full Name"
                  className="!bg-[#FFFAF4] outline-none border !border-[#E5DFD7] text-[#000] placeholder:text-[#9CA3AF] text-[13px] focus:border-[#000] block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  onChange={handleChange}
                  value={formData.name}
                  minLength={2}
                  required
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block mb-2 text-[14px] leading-[16px] font-[500] text-[#000]"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="name@xyz.com"
                  className="!bg-[#FFFAF4] outline-none border !border-[#E5DFD7] text-[#000] placeholder:text-[#9CA3AF] text-[13px] focus:border-[#000] block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  onChange={handleChange}
                  value={formData.email}
                  required
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="phone_no"
                  className="block mb-2 text-[14px] leading-[16px] font-[500] text-[#000]"
                >
                  Phone number
                </label>
                <input
                  type="tel"
                  id="phone_no"
                  name="phone_no"
                  placeholder="08012345678"
                  maxLength={11}
                  className="!bg-[#FFFAF4] outline-none border !border-[#E5DFD7] text-[#000] placeholder:text-[#9CA3AF] text-[13px] focus:border-[#000] block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  onChange={handleChange}
                  value={formData.phone_no}
                  required
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="subject"
                  className="block mb-2 text-[14px] leading-[16px] font-[500] text-[#000]"
                >
                  Subject
                </label>
                <select
                  id="subject"
                  name="subject"
                  className="font-BRFirma !bg-[#FFFAF4] outline-none border !border-[#E5DFD7] text-[#000] placeholder:text-[#9CA3AF] text-[13px] focus:border-[#000] block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  onChange={handleChange}
                  value={formData.subject}
                  required
                >
                  <option
                    value={Subject.INQUIRY}
                    className="font-BRFirma text-[#000]"
                  >
                    Inquiry
                  </option>
                  <option
                    value={Subject.COMPLAINT}
                    className="font-BRFirma text-[#000]"
                  >
                    Complaint
                  </option>
                  <option
                    value={Subject.SUGGESTION}
                    className="font-BRFirma text-[#000]"
                  >
                    Suggestion
                  </option>
                </select>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
              <div className="mb-4">
                <label
                  htmlFor="content"
                  className="block mb-2 text-[12px] font-[500] text-[#000]"
                >
                  Complaints
                </label>
                <textarea
                  name="content"
                  id="content"
                  rows={5}
                  className="!bg-[#FFFAF4] outline-none border !border-[#E5DFD7] text-[#000] placeholder:text-[#9CA3AF] text-[13px] focus:border-[#000] block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  placeholder="Make complaints"
                  onChange={handleChange}
                  value={formData.content}
                  required
                ></textarea>
              </div>
            </div>

            <div className="mt-5">
              <Button
                linkText="Submit"
                onClick={() => {}}
                className={`px-10`}
              />
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default FormContact;
