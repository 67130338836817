import { axiosClient } from "../utlis/axiosClient";

export const getLocations = async <T>() => {
  try {
    const response = await axiosClient.get(`/public/outlets`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const locations: T = response.data;
    return locations;
  } catch (error) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};
