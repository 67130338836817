const BreadCrumb = () => {
  return (
    <section className="bg-[#FFFAF4] px-[20px] py-[100px] border-[#E2D2BE] border-b">
      <div className="flex flex-row justify-center">
        <div className="w-2/2 lg:w-2/5 text-center">
          <h1 className="text-[#041D05] font-[600] text-[40px] leading-[50px]">
            Contact Us
          </h1>
        </div>
      </div>
    </section>
  );
};

export default BreadCrumb;
