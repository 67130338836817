// COMPONENTS, LIBs & UTILs
import { sortFn } from "../../../../lib/utlis/helper";
import MealPlanCard from "../MealPlanCard";

// STYLES
import styles from "./MealPlansSection.module.scss";

const MealPlansSection = ({ plansData }: any) => {
  return (
    <section id="availablePlans" className={`${styles.mealPlansSection}`}>
      <h2 className={styles.mealPlansSectionTitle}>Available Meal Plans</h2>

      <div className={`xxl:container ${styles.mealPlansCardsContainer}`}>
        {/* SORTFN is a function that sorts the data in order of LIFO to display consistent data */}
        {plansData[0]?.plan_types
          .sort(sortFn)
          .map((item: any, index: number) => (
            <MealPlanCard
              key={item.id}
              name={item.name}
              description={item.description}
              image={item.image[0]}
              reverseCard={index % 2 === 0 ? true : false}
              id={item.id}
              minDays={item.duration[0]?.days}
              maxDays={item.duration[1]?.days}
              minPrice={Number(item.duration[0]?.price)}
              maxPrice={Number(item.duration[1]?.price)}
              minDiscountPrice={Number(item.duration[0]?.discount_price)}
              maxDiscountPrice={Number(item.duration[1]?.discount_price)}
              duration={item.duration}
              linkUrl={`/plans/${plansData?.[0]?.slug}/${item.slug}`}
              downloadLink={item.duration[0]?.pdf_url}
            />
          ))}
      </div>
    </section>
  );
};

export default MealPlansSection;
