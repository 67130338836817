import about from "../../../../assests/images/AboutPage/our-spaces.webp";
import Button from "../../../common/Button";

const OurSpaces = () => {
  return (
    <section className="bg-white px-[20px] py-[150px]">
      <div className="container">
        <h2 className="text-[#FD9F28] font-[600] text-[40px] leading-[48px]">
          <span className="text-[#041D05]">Our</span> Spaces
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4  mt-6">
          <div className="w-[95%] text-[#041D05] font-[400] text-[16px] leading-[29px]">
            <p>
              Our spaces stay true to what we believe in and stand for; open,
              inviting, warm and comfortable. We provide a serene, conducive and
              hygienic environment for people to have easy access to
              nutrient-rich, safe and healthy food choices.{" "}
            </p>
            <p>
              Our open kitchens reflect our commitment to transparency and
              integrity because we want our guests to know exactly what goes
              into their food, where it came from and how we make it fresh.
            </p>
            <p>
              Our walls are wood panelled, decorated with natural flowers and
              bear unique work of art, to celebrate human&apos;s connection to
              nature and beauty. Our spaces encourage community and fosters
              relationship.
            </p>
            <p className="mb-6">
              We heartily welcome you into your fresh home, at every of our So
              Fresh outlet.
            </p>

            <Button linkText="Find Our Spaces" linkHref="/location" />
          </div>
          <div>
            <img src={about} alt="about" className="w-full" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurSpaces;
