import { FC, useEffect, useState } from "react";
import styles from "./OrderSuccessModal.module.scss";
import OrderSuccessful from "../../../assests/images/OrderHistoryPage/successfull-order.png";

type OrderSuccessModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const OrderSuccessModal: FC<OrderSuccessModalProps> = ({ isOpen, onClose }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(isOpen);

  const closeModal = () => {
    setModalOpen(false);
    onClose();
  };

  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modalOpen]);

  return (
    <>
      {modalOpen && (
        <div className={styles.orderSuccessModal}>
          <div className={styles.orderSuccessModalContainer}>
            <figure className={styles.orderSuccessModalContent}>
              <img src={OrderSuccessful} alt={`Order Successful`} />
              <figcaption className={styles.orderSuccessModalCaption}>
                <h1 className={styles.orderSuccessModalTitle}>
                  Order Received
                </h1>
                <p className={styles.orderSuccessModalDetails}>
                  We have received your order, sit back while your order is
                  prepared!
                </p>
              </figcaption>
            </figure>

            <button
              className={styles.orderSuccessModalCloseBtn}
              onClick={closeModal}
            >
              Back to website
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderSuccessModal;
