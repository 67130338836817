import Footer from "../../components/layouts/Footer";
import Navbar from "../../components/layouts/NavBar";
import Article from "./Article";
import Aside from "./Aside";

const ReturnPolicy = () => {
  return (
    <main className="min-h-screen flex flex-col justify-between">
      <Navbar />
      <div className="container flex justify-center gap-8 xl:gap-16 my-6 xl:my-8">
        <Aside />

        <section className="w-full sm:w-3/5 xl:w-2/3 self-start flex flex-col justify-center gap-10 mb-2 xl:mb-0 px-6 sm:px-0 pe-7 xl:pe-0">
          <Article
            title={`We are Dedicated to Our Customers' Satisfaction`}
            paragraphs={[
              `IF YOU ARE NOT 100% SATISFIED WITH A PURCHASE MADE FROM SO FRESH, PLEASE TALK TO US AND WE'LL MAKE IT RIGHT.`,
              `We seek to ensure your optimum satisfaction every single time you make a purchase and consume any of our products. However, if for any reason you are unhappy with any So Fresh Product, kindly contact us within 6 hours after the purchase.`,
              `Please note that all unsatisfactory products are to be returned to us, intact, in their original packaging with your purchase invoice, within 6 hours of purchase for a replacement. It is important for us to retrieve the products from you, before making a replacement. This will enable us serve you better.`,
            ]}
          />
          <div>
            <p className="text-[#041D05] text-sm font-light leading-[1.6]">
              <span>To get started, please email: </span>
              <a
                className="inline underline font-medium hover:text-[#FD9F28] hover:no-underline transition duration-300 ease-in"
                href="mailto:info@sofreshng.com"
              >
                info@sofreshng.com
              </a>
              <span> / </span>
              <a
                className="inline underline font-medium hover:text-[#FD9F28] hover:no-underline transition duration-300 ease-in"
                href="tel:+08062868430"
              >
                08062868430
              </a>
              <span> - Whatsapp only</span>
            </p>
            <p className="text-[#041D05] text-sm font-light leading-[1.6]">
              <span>Thank you. We love to help.</span>
            </p>
          </div>
        </section>
      </div>
      <Footer />
    </main>
  );
};

export default ReturnPolicy;
