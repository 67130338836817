// COMPONENTS
import { formatToNigerianNaira } from "../../../../lib/utlis/helper";
import Button from "../../../common/Button";

// STYLES
import styles from "./MealPlanCard.module.scss";

const MealPlanCard = ({
  reverseCard,
  name,
  description,
  image,
  duration,
  minDays,
  maxDays,
  maxDiscountPrice,
  minDiscountPrice,
  minPrice,
  maxPrice,
  linkUrl,
  downloadLink,
}: IMealPlanCardProps) => {
  return (
    <figure
      className={`${styles.mealPlansCard} ${
        reverseCard && styles.reverseMealPlansCard
      }`}
    >
      <figcaption className={styles.mealPlansCardBody}>
        <article className={styles.mealPlansCardCaption}>
          <h3 className={styles.cardTitle}>{name}</h3>

          <p className={styles.cardDescription}>{description}</p>
        </article>

        <div className={styles.mealPlansCardDetails}>
          <article className={styles.cardDetailContent}>
            <h4 className={styles.detailCaption}>Duration</h4>
            <p className={styles.detailDescription}>
              {duration.length > 1
                ? `${minDays} - ${maxDays} Days`
                : `${minDays} Day(s)`}
            </p>
          </article>

          <article className={styles.cardDetailContent}>
            <h4 className={styles.detailCaption}>Cost</h4>
            <p
              className={`${styles.detailDescription} ${
                maxDiscountPrice > 0 || minDiscountPrice > 0
                  ? "text-red-600 bg-red-200 line-through"
                  : "text-[#339E35] bg-[#EBF3EB]"
              }`}
            >
              {duration.length > 1 ? (
                <span>
                  {`${formatToNigerianNaira(
                    minPrice,
                  )} - ${formatToNigerianNaira(maxPrice)}`}
                </span>
              ) : (
                <span>{`${formatToNigerianNaira(minPrice)}`}</span>
              )}
            </p>
          </article>

          {(maxDiscountPrice > 0 || minDiscountPrice > 0) && (
            <article className={styles.cardDetailContent}>
              <h4 className={styles.detailCaption}>Discount Cost</h4>
              <p
                className={`%{styles.detailDescription} text-[#339E35] bg-[#EBF3EB]`}
              >
                {duration.length > 1 ? (
                  <span>
                    {`${formatToNigerianNaira(
                      minDiscountPrice,
                    )} - ${formatToNigerianNaira(maxDiscountPrice)}`}
                  </span>
                ) : (
                  <span>{formatToNigerianNaira(minDiscountPrice)}</span>
                )}
              </p>
            </article>
          )}
        </div>

        <div className={styles.mealPlansCardFoter}>
          <Button
            linkHref={downloadLink || "/meal-plans"}
            linkText={"View Meal Plan Calendar"}
            lightBtn
            download={name}
          />
          <Button linkHref={linkUrl} linkText={"Order This Plan"} />
        </div>
      </figcaption>

      <div className={styles.mealPlanCardImage}>
        <img className={styles.cardImage} src={image} alt={name} />
      </div>
    </figure>
  );
};

export default MealPlanCard;
