// REACT DEFAULTS
import { Helmet, HelmetProvider } from "react-helmet-async";

// COMPONENTS
import NavBar from "../NavBar";
import Footer from "../Footer";
// import PromoBanner from "../../common/PromoBanner";

const Layout = ({ title, description, children }: ILayout) => {
  return (
    <HelmetProvider>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <title>{`So Fresh | ${title}`}</title>
        <meta name="description" content={description} />
      </Helmet>

      {/* <PromoBanner /> */}
      <NavBar />
      <>{children}</>
      <Footer />
    </HelmetProvider>
  );
};

export default Layout;
