// REACT DEFAULTS
import { FC } from "react";

// COMPONENTS
import OrderCard from "../OrderCard";
import ExtrasCard from "../ExtrasCard";

// STYLES
import styles from "./SingleOrderModal.module.scss";

const SingleOrderModal: FC<{ singleProductsData: TCartItem }> = ({
  singleProductsData,
}) => {
  return (
    <>
      <OrderCard
        imgURL={
          singleProductsData.image?.length ? singleProductsData?.image[0] : ""
        }
        orderName={singleProductsData?.name ?? ""}
        orderDescription={singleProductsData?.description ?? ""}
        orderPrice={Number(singleProductsData?.price)}
        discountedOrderPrice={Number(singleProductsData?.discount)}
        singleProductsData={singleProductsData}
      />

      {singleProductsData.extras.length > 0 && (
        <h3 className={styles.extrasCardsTitle}>
          Add Extras to Make Your Meal Yummier!
        </h3>
      )}

      <div className={styles.extrasCardContainer}>
        {singleProductsData.extras.length > 0 &&
          singleProductsData.extras.map(({ quantity, ...rest }, index) => (
            <ExtrasCard
              key={index}
              imgURL={rest.image?.length ? rest.image[0] : ""}
              extrasName={rest?.name}
              extraPrice={Number(rest?.price)}
              singleProductsDataSlug={singleProductsData.slug}
              extra={{ ...rest }}
            />
          ))}
      </div>
    </>
  );
};

export default SingleOrderModal;
