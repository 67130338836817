import meal from "../../../../assests/images/AboutPage/meal.svg";
import house from "../../../../assests/images/AboutPage/house.svg";
import customer from "../../../../assests/images/AboutPage/customers.svg";

const Tractions = () => {
  return (
    <section className="bg-[#EBF5EB] px-[20px] py-[100px] md:px-20">
      <div className="container">
        <div className="flex flex-col md:flex-row items-center justify-center md:justify-between">
          <div className="text-center mb-8 md:mb-0">
            <img src={meal} alt="meal" className="mx-auto" />
            <h2 className="text-[#041D05] font-[700] text-3xl md:text-4xl leading-8 md:leading-14 my-4">
              5 Million +
            </h2>
            <p className="text-[#041D05] font-[400] text-lg md:text-xl leading-6 md:leading-7">
              Meals sold
            </p>
          </div>
          <div className="text-center mb-8 md:mb-0">
            <img src={house} alt="house" className="mx-auto" />
            <h2 className="text-[#041D05] font-[700] text-3xl md:text-4xl leading-8 md:leading-14 my-4">
              15+
            </h2>
            <p className="text-[#041D05] font-[400] text-lg md:text-xl leading-6 md:leading-7">
              Stores opened across
              <br />
              the country
            </p>
          </div>
          <div className="text-center">
            <img src={customer} alt="customer" className="mx-auto" />
            <h2 className="text-[#041D05] font-[700] text-3xl md:text-4xl leading-8 md:leading-14 my-4">
              150,000+
            </h2>
            <p className="text-[#041D05] font-[400] text-lg md:text-xl leading-6 md:leading-7">
              Satisfied customers
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Tractions;
