// REACT & DEFAULTS
import { HashLink } from "react-router-hash-link";

// COMPONENTS
import { useLocation } from "react-router-dom";

// STYLES
import styles from "./MealPlansBanner.module.scss";

type props = {
  name: string;
  description: string;
  image: string;
};
type TMealPlanBannerProps = {
  plansData: props;
};

const MealPlansBanner = ({ plansData }: TMealPlanBannerProps) => {
  const location = useLocation();

  return (
    <header className={`xxl:container ${styles.mealPlansHero}`}>
      <article className={styles.mealPlansHeroCaption}>
        <h1 className={styles.mealPlansHeroTitle}>{plansData.name}</h1>

        <div className={styles.mealPlansHeroTextContainer}>
          <p className={styles.mealPlansHeroText}>{plansData.description}</p>
        </div>

        <HashLink
          to={`${location.pathname}#availablePlans`}
          scroll={(el) =>
            el.scrollIntoView({ behavior: "smooth", block: "start" })
          }
          className={styles.plansHeroBtn}
        >
          {`View Available ${plansData.name}`}
        </HashLink>
      </article>

      <div className={styles.mealPlansHeroBanner}>
        <img
          className={styles.mealPlansHeroBannerImage}
          src={plansData.image}
          alt={plansData.name}
        />
      </div>
    </header>
  );
};

export default MealPlansBanner;
