import axios from "axios";

const BASE_URL =
  process.env.REACT_APP_ENV === "development"
    ? // process.env.NODE_ENV === 'development'
      // ? // process.env.NODE_ENV === 'development'
      process.env.REACT_APP_DEV
    : process.env.REACT_APP_PROD;

export const axiosClient = axios.create({
  baseURL: BASE_URL,
});
