import { axiosClient } from "../utlis/axiosClient";

type ExtrasInCart = {
  extra_id: number;
  quantity: number;
  total_amount: number;
};

type ProductsInCart = {
  product_id: number;
  total_amount: number;
  quantity: number;
  extras?: ExtrasInCart[];
};
interface PlansInCart {
  plan_type_id: number;
  plan_duration: number;
  plan_preference: string;
  total_amount: number;
  quantity: number;
  slug: string;
  name: string;
  image: string;
  pdf_url: string;
}

interface IOrder {
  delivery_type: string;
  store_id?: number;
  delivery_fee?: number;
  delivery_address?: string;
  recipient?: {
    recipient_name: string;
    recipient_mobile_number: string;
  };
  customer_data?: {
    allergies: string;
    expectant_mum?: string;
  };
}

interface ICartData {
  cart: {
    products: ProductsInCart[];
    plans: PlansInCart[];
  };
  order: IOrder;
}

export const addToCart = async (
  { token }: { token: string },
  data: ICartData,
) => {
  try {
    const response = await axiosClient.post(`/user/orders`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: unknown) {
    const err = error as IErrorResponse;
    throw new Error(err?.response?.data?.message ?? "Unknown error");
    // I used the nullish coalescing operator (`??`) to provide a default value, in-case of null or undefined.
  }
};
