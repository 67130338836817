// REACT DEFAULTS
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// COMPONENTS, ICONS, LIBs & UTILS
import { OrderContext } from "../../../context/OrderContext";
import OrderPlusExtraModal from "../OrderPlusExtraModal";
import { formatToNigerianNaira } from "../../../lib/utlis/helper";
import { VscClose } from "react-icons/vsc";
import { toast } from "../Toast";

// STYLES
import styles from "./CartItemCard.module.scss";
import { useQuery } from "@tanstack/react-query";
import { getStores } from "../../../lib/handlers/storeHandler";

type StoreDataProps = {
  id: number;
  name: string;
  slug: string;
};

type StoresDataType = StoreDataProps[];

const CartItemCard = () => {
  const navigate = useNavigate();
  const { cartState, dispatch } = useContext(OrderContext);
  const [isModalOpen, setIsModalOpen] = useState({
    isOpen: false,
    slug: "",
  });

  // FUNCTION TO CALCULATE TOTAL MENU PRICE PLUS EXTRAS
  const menuPrice = (item: any) =>
    (item.discount ? Number(item.discount) : Number(item.price)) *
      item.quantity +
    item.extras.reduce(
      (total: number, extra: { price: string; quantity: number }) => {
        return total + Number(extra.price) * extra.quantity;
      },
      0,
    );
  // const menuPrice = (item: any) =>
  //   Number(item.price) * item.quantity +
  //   item.extras.reduce(
  //     (total: number, extra: { price: string; quantity: number }) => {
  //       return total + Number(extra.price) * extra.quantity
  //     },
  //     0
  //   )

  // GET STORE DATA
  const { data: storesData, isLoading: isStoresDataLoading } = useQuery(
    ["stores"],
    () => getStores(),
  );

  const getStoreSlugById = (
    stores: StoresDataType,
    storeID: number | null,
  ): string | undefined => {
    const foundStore = stores?.find(
      (store: StoreDataProps) => store.id === storeID,
    );
    return foundStore ? foundStore.slug : undefined;
  };

  // GET STORE ID
  const getSelectedStore = localStorage.getItem("soFreshStore");
  const selectedStore = getSelectedStore
    ? JSON.parse(getSelectedStore)?.id
    : null;

  // GET STORE ID SLUG
  const storeSlug =
    !isStoresDataLoading &&
    selectedStore &&
    getStoreSlugById(storesData, Number(selectedStore));

  const removeProductFromCart = (itemSlug: string) => {
    dispatch({
      type: "REMOVE_PRODUCT_FROM_CART",
      payload: itemSlug,
    });
    toast.info("Item Removed From Cart");

    if (cartState.products.length <= 1 && cartState.plans.length < 1) {
      selectedStore ? navigate(`/stores/${storeSlug}`) : navigate("/");
    }
  };

  const removePlanFromCart = (slug: string) => {
    dispatch({
      type: "REMOVE_PLAN_FROM_CART",
      payload: slug,
    });
    toast.info("Plan Removed From Cart");

    // if (cartState.products.length <= 1 && cartState.plans.length < 1) {
    // selectedStore ? navigate(`/stores/${storeSlug}`) : navigate("/");
    //   navigate('/')
    // }
  };

  useEffect(() => {
    if (cartState.plans.length < 1) {
      navigate("/");
    }
  }, [cartState.plans.length, navigate]);

  return (
    <>
      {cartState.products.map((cartItem) => (
        <article className={styles.cartItemCard} key={cartItem.slug}>
          <button
            className={styles.removeFromCartBtn}
            onClick={() => removeProductFromCart(cartItem.slug)}
          >
            <VscClose className={styles.removeItemIcon} />
          </button>

          <figure
            className={styles.cartItem}
            // onClick={() => toggleModal(cartItem.slug)}
          >
            <img
              src={cartItem?.image?.[0]}
              alt={cartItem.name}
              className={styles.cartItemImage}
            />

            <figcaption className={styles.cartItemDetails}>
              <h2 className={styles.cartItemName}>
                {cartItem.name} <small>x</small> {cartItem.quantity}
              </h2>
              {cartItem.extras && (
                <p className={styles.cartItemExtras}>
                  {cartItem.extras.map((extra) => (
                    <span
                      key={extra.slug}
                      className={styles.cartItemExtra}
                    >{`${extra.name} x${extra.quantity}`}</span>
                  ))}
                </p>
              )}
              <p className={styles.cartItemPrice}>
                {formatToNigerianNaira(menuPrice(cartItem))}
              </p>

              <div className="w-full flex items-center justify-start gap-[2px] pt-4">
                <button
                  className={`h-8 w-8 bg-[#EBF3EB] disabled:bg-[#E2D2BF] text-2xl flex items-center justify-center`}
                  onClick={() => {
                    dispatch({
                      type: "EDIT_ORDER_QUANTITY",
                      payload: { ...cartItem, type: "minus" },
                    });
                  }}
                  disabled={cartItem.order_qty <= 1}
                >
                  -
                </button>

                <p
                  className={`h-8 w-8 bg-[#EBF3EB] flex items-center justify-center ${
                    cartItem.order_qty === 0 ? "text-red-600" : "text-[#339E35]"
                  } text-sm font-medium leading-[140%]`}
                >
                  {cartItem.order_qty}
                </p>

                <button
                  className={`h-8 w-8 bg-[#EBF3EB] disabled:bg-[#E2D2BF] text-2xl flex items-center justify-center`}
                  onClick={() => {
                    dispatch({
                      type: "EDIT_ORDER_QUANTITY",
                      payload: { ...cartItem, type: "add" },
                    });
                  }}
                >
                  +
                </button>
              </div>
            </figcaption>
          </figure>
        </article>
      ))}

      {cartState.plans.map((plan) => (
        <article className={styles.cartItemCard} key={plan.slug}>
          <button
            className={styles.removeFromCartBtn}
            onClick={() => removePlanFromCart(plan.slug)}
          >
            <VscClose className={styles.removeItemIcon} />
          </button>

          <figure className={styles.cartItem}>
            <img
              src={plan?.image}
              alt={plan.slug}
              className={styles.cartItemImage}
            />

            <figcaption className={styles.cartItemDetails}>
              <h2 className={styles.cartItemName}>{plan.slug}</h2>

              <p className={styles.cartItemPrice}>
                {formatToNigerianNaira(plan.total_amount)}
              </p>

              <a
                className={styles.cartItemDownloadLink}
                href={plan.pdf_url}
                target="_blank"
                rel="noopener noreferrer"
                download={`${plan.name} specification`}
              >{`View ${plan.name} Plan Specification`}</a>
            </figcaption>
          </figure>
        </article>
      ))}

      {isModalOpen.isOpen && isModalOpen.slug && (
        <OrderPlusExtraModal
          slug={isModalOpen.slug}
          handleClick={() =>
            setIsModalOpen((prev) => ({ ...prev, isOpen: !prev.isOpen }))
          }
          toggleModal={() => {
            setIsModalOpen((prev) => ({ ...prev, isOpen: !prev.isOpen }));
          }}
        />
      )}
    </>
  );
};

export default CartItemCard;
