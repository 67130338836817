// REACT DEFAULTS
import { Link } from "react-router-dom";

// IMAGES & ICONS
import { HiOutlineArrowRight } from "react-icons/hi";

// STYLES
import styles from "./SectionHeader.module.scss";

const SectionHeader = ({
  title,
  caption,
  linkText,
  LinkHref,
}: ISectionHeader) => {
  return (
    <div className={styles.sectionHeader}>
      <h2 className={styles.sectionHeaderTitle}>
        {title} <span className={styles.brandColor}>{caption}</span>
      </h2>

      <Link
        className={`${styles.sectionHeaderLink} ${styles.brandColor}`}
        to={LinkHref}
      >
        <span className={styles.sectionHeaderLinkText}>{linkText}</span>
        <span className={styles.sectionHeaderLinkIcon}>
          <HiOutlineArrowRight />
        </span>
      </Link>
    </div>
  );
};

export default SectionHeader;
