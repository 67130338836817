import value from "../../../../assests/images/AboutPage/values.png";

const Values = () => {
  return (
    <section className="bg-[#FFFAF4] px-[20px] py-[100px]">
      <div className="flex flex-row justify-center">
        <div className="w-2/2 lg:w-2/5 text-center">
          <h2 className="text-[#FD9F28] font-[600] text-[40px] leading-[48px]">
            <span className="text-[#041D05]">Our</span> Values
          </h2>
          <p className="text-[#041D05] font-[400] text-[16px] leading-[25px] mt-6">
            These five core values embody what we stand for, what drives us and
            our way of doing things the right way. They keep us focused and help
            us make decisions about every aspect of our business
          </p>
          <div>
            <img src={value} alt="value" className="w-full mx-auto" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Values;
