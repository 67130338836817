import { axiosClient } from "../utlis/axiosClient";

export type ReviewData = {
  product_id?: string;
  plan_type_id?: string;
  store_id?: string;
  comment: string;
  rating: number;
};

type AddReviewData = {
  token: string;
  data: ReviewData;
};

export const addReview = async ({ token, data }: AddReviewData) => {
  try {
    const response = await axiosClient.post(`user/reviews`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: unknown) {
    const err = error as IErrorResponse;
    throw new Error(err?.response?.data?.message ?? "Unknown error");
    // I used the nullish coalescing operator (`??`) to provide a default value, in-case of null or undefined.
  }
};
