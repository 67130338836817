import {
  FC,
  ChangeEvent,
  FormEvent,
  useEffect,
  useState,
  useContext,
} from "react";
import { useNavigate } from "react-router-dom";

// COMPONENTS, LIBs & UTILs
import { OrderContext } from "../../../context/OrderContext";
import { formatToNigerianNaira } from "../../../lib/utlis/helper";
import { VscClose } from "react-icons/vsc";
import { Oval } from "react-loader-spinner";
import { toast } from "../Toast";

// STYLES
import styles from "./singlePlanTypeModal.module.scss";

interface PlanTypeData {
  id: number;
  name: string;
  plan_types: PlanType[];
}

interface PlanType {
  id: number;
  name: string;
  duration: Duration[];
  slug: string;
  image: string;
}

interface Duration {
  days: number;
  price: number;
  pdf_url: string;
}

interface PreferenceStateProps {
  planPreferences: string;
  deliveryStartDate: string;
}

interface planTypeFormData {
  plan_type_id: number;
  plan_duration: number;
  total_amount: number;
  quantity: number;
  slug: string;
  name: string;
  image: string;
  pdf_url: string;
  plan_preference: string;
  delivery_start_date: string;
}

type SinglePlanTypeModalProps = {
  isModalOpen: boolean;
  onClose: () => void;
  planTypeData: PlanTypeData[];
  isplanTypeDataLoading: boolean;
};

const SinglePlanTypeModal: FC<SinglePlanTypeModalProps> = ({
  isModalOpen,
  onClose,
  planTypeData,
  isplanTypeDataLoading,
}) => {
  const { dispatch } = useContext(OrderContext);
  const navigate = useNavigate();

  // HANDLE MODAL
  const [modalOpen, setModalOpen] = useState<boolean>(isModalOpen);
  const closeModal = () => {
    setModalOpen(false);
    onClose();
  };

  // FREEZE PAGE SCROLLING WHEN MODAL IS OPEN
  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modalOpen]);

  // DURATION ARRAY TO POPULATE THE SELECT FIELD AND SWITCH PRICE onChange
  const [durationArr, setDurationArr] = useState<any[]>([]);
  useEffect(() => {
    planTypeData &&
      planTypeData[0]?.plan_types &&
      setDurationArr(
        planTypeData[0].plan_types[0].duration.map((day: any) => day),
      );
  }, [planTypeData]);

  const [selectedDays, setSelectedDays] = useState<number>(3);

  useEffect(() => {
    durationArr && setSelectedDays(durationArr[0]?.days);
  }, [durationArr]);

  const selectedDuration = durationArr.find(
    (duration) => duration.days === selectedDays,
  );

  // SET MINIMUM DATE TO TODAY's DATE
  const todaysDate = new Date(),
    minDate = todaysDate.toISOString().substring(0, 10);

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedDays(Number(event.target.value));
  };

  const [planPreferenceState, setPlanPreferenceState] =
    useState<PreferenceStateProps>({
      planPreferences: "",
      deliveryStartDate: new Date().toISOString().split("T")[0],
    });

  const handlePlanPreferenceChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;
    setPlanPreferenceState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  // useEffect(() => {
  //   console.log('planPreferenceState: ', planPreferenceState)
  // }, [planPreferenceState])
  // console.log("selectedDuration: ", selectedDuration);

  const price: number =
    selectedDuration && selectedDuration?.discount_price
      ? selectedDuration?.discount_price
      : selectedDuration?.price
      ? selectedDuration?.price
      : 0;

  const handlePlanAddToCart = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const planTypeFormData: planTypeFormData = {
      plan_type_id: planTypeData[0].plan_types[0].id,
      plan_duration: selectedDays,
      total_amount: price,
      quantity: 1,
      slug: planTypeData[0].plan_types[0].slug,
      image: planTypeData[0].plan_types[0].image[0],
      name: planTypeData[0].plan_types[0].name,
      pdf_url: planTypeData[0].plan_types[0].duration[0].pdf_url,
      plan_preference: planPreferenceState?.planPreferences,
      delivery_start_date: planPreferenceState?.deliveryStartDate,
    };

    dispatch({ type: "ADD_PLAN_TO_CART", payload: planTypeFormData });

    navigate("/cart");
    toast.success("Plan Added to Cart");
  };

  return (
    <>
      {modalOpen && (
        <div className={styles.singlePlanTypeModal}>
          <div className={styles.singlePlanTypeModalContainer}>
            <button
              className={styles.singlePlanTypeModalCloseBtn}
              onClick={closeModal}
            >
              <VscClose />
            </button>

            {isplanTypeDataLoading ? (
              <div className={styles.modalLoader}>
                <Oval
                  wrapperClass="mx-auto"
                  height={50}
                  width={50}
                  strokeWidth={5}
                />
              </div>
            ) : (
              <article className={styles.singlePlanTypeModalContent}>
                <div className={styles.singlePlanTypeModalHeading}>
                  <h3 className={styles.singlePlanTypeModalTitle}>
                    {`${planTypeData[0].plan_types[0].name} Plan Specification`}
                  </h3>
                  <p className={styles.singlePlanTypeModalSubTitle}>
                    Kindly specify when and how you will like your meals
                    delivered
                  </p>
                </div>

                <form
                  className={styles.singlePlanTypeModalForm}
                  onSubmit={handlePlanAddToCart}
                >
                  {planTypeData &&
                    planTypeData[0]?.plan_types?.map((item: any) => (
                      <div className={styles.formDynamicData} key={item.id}>
                        <div className={styles.formGroup}>
                          <label
                            className={styles.formLabel}
                            htmlFor="planDuration"
                          >
                            Meal plan period
                          </label>
                          <select
                            name="planDuration"
                            id="planDuration"
                            className={`${styles.formField} ${styles.formFieldSelect}`}
                            value={selectedDays}
                            onChange={handleSelectChange}
                          >
                            {durationArr.map((duration: any) => (
                              <option
                                className={styles.formFieldOption}
                                key={duration.days}
                                value={duration.days}
                              >
                                {`${duration.days} days`}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className={styles.formGroup}>
                          <label
                            className={styles.formLabel}
                            htmlFor="planCost"
                          >
                            Meal Cost
                          </label>
                          <input
                            type="text"
                            name="planCost"
                            id="planCost"
                            className={styles.formField}
                            value={formatToNigerianNaira(price)}
                            disabled
                          />
                        </div>

                        <div className={styles.formGroup}>
                          <label
                            className={styles.formLabel}
                            htmlFor="startDate"
                          >
                            Start Date
                          </label>
                          <input
                            className={styles.formField}
                            type="date"
                            min={minDate}
                            name="deliveryStartDate"
                            id="startDate"
                            value={planPreferenceState.deliveryStartDate}
                            onChange={handlePlanPreferenceChange}
                          />
                        </div>
                      </div>
                    ))}

                  <div className={styles.formGroup}>
                    <label
                      className={styles.formLabel}
                      htmlFor="planPreferences"
                    >
                      Menu preferences and adjustments
                    </label>
                    <textarea
                      name="planPreferences"
                      id="planPreferences"
                      className={styles.formField}
                      placeholder={`Kindly state your menu preferences if any`}
                      value={planPreferenceState.planPreferences}
                      onChange={handlePlanPreferenceChange}
                    ></textarea>
                  </div>

                  <div className={styles.singlePlanTypeModalSubmitBtnContainer}>
                    <button className={styles.singlePlanTypeModalSubmitBtn}>
                      Add Meal Plan To Cart
                    </button>
                  </div>
                </form>
              </article>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SinglePlanTypeModal;
