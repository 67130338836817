import { axiosClient } from "../utlis/axiosClient";

export const loginUser: (
  data: ILoginData,
) => Promise<IUserLoginResponse> = async (data: ILoginData) => {
  try {
    const response = await axiosClient.post("/auth/login", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error: any) {
    // console.error({ error })
    if (error?.response?.data?.message) {
      throw error?.response?.data?.message;
    }
    throw new Error("An error occured. Please try again.");
  }
};

export const registerUser: (
  data: IUserRegistrationData,
) => Promise<any> = async (data: IUserRegistrationData) => {
  try {
    const response = await axiosClient.post("/register", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log('RESPONSE_FROM_HANDLER: ', response)

    return response.data;
  } catch (error: any) {
    // console.error({ error })
    if (error?.response?.data?.error?.email) {
      throw error?.response?.data?.error.email[0];
    }
    if (error?.response?.data?.error?.phone_no) {
      throw error?.response?.data?.error.phone_no[0];
    }
    if (error?.response?.data?.error?.password) {
      throw error?.response?.data?.error.password[0];
    }
    throw new Error("An error occured. Please try again.");
  }
};
