import {
  FC,
  ReactNode,
  createContext,
  useReducer,
  Reducer,
  Dispatch,
  useEffect,
} from "react";

interface planCartData {
  plan_type_id: number;
  plan_duration: number;
  total_amount: number;
  quantity: number;
  slug: string;
  name: string;
  image: string;
  pdf_url: string;
  plan_preference: string;
  delivery_start_date: string;
}

type TReducerActions =
  | {
      type: "INCREASE_PRODUCT_QTY_IN_CART";
      payload: Pick<
        TCartItem,
        Exclude<keyof TCartItem, "quantity" & "order_qty">
      >;
    }
  | {
      type: "EDIT_ORDER_QUANTITY";
      payload: TCartItem & { type: "add" | "minus" };
    }
  | {
      type: "REDUCE_PRODUCT_QTY_IN_CART";
      payload: string;
    }
  | {
      type: "REMOVE_PRODUCT_FROM_CART";
      payload: string;
    }
  | {
      type: "ADD_EXTRA_TO_CART";
      payload: Pick<
        TCartExtrasData,
        Exclude<keyof TCartExtrasData, "quantity">
      > & {
        product_slug: string;
      };
    }
  | {
      type: "REMOVE_EXTRA_FROM_CART";
      payload: {
        product_slug: string;
        extra_slug: string;
      };
    }
  | {
      type: "ADD_PLAN_TO_CART";
      payload: planCartData;
    }
  | {
      type: "REMOVE_PLAN_FROM_CART";
      payload: string;
    }
  | {
      type: "DELETE_ALL_ITEMS_IN_CART";
      payload: EmptyCart;
    };

interface EmptyCart {
  products: [];
  plans: [];
}

interface IOrderContext {
  cartState: {
    products: Array<TCartItem>;
    plans: Array<planCartData>;
  };
  dispatch: Dispatch<TReducerActions>;
}

const initCartState = {
  cartState: {
    products: [],
    plans: [],
  },
  dispatch: () => null,
};

export const OrderContext = createContext<IOrderContext>(initCartState);

interface OrderProviderProps {
  children: ReactNode;
}

const cartReducer: Reducer<
  {
    products: Array<TCartItem>;
    plans: Array<planCartData>;
  },
  TReducerActions
> = (cartState, action) => {
  switch (action.type) {
    case "INCREASE_PRODUCT_QTY_IN_CART": {
      const isItemInCart = cartState.products.some(
        (item) => item.slug === action.payload.slug,
      );
      if (isItemInCart) {
        return {
          products: cartState.products.map((item) =>
            item.slug === action.payload.slug
              ? { ...item, quantity: item.quantity + 1 }
              : item,
          ),
          plans: cartState.plans,
        };
      } else {
        const newItem = {
          ...action.payload,
          quantity: 1,
          order_qty: 1,
          extras: [],
        };
        return {
          products: [...cartState.products, newItem],
          plans: cartState.plans,
        };
      }
    }

    case "EDIT_ORDER_QUANTITY": {
      return {
        products: cartState.products.map((item) =>
          item.slug === action.payload.slug
            ? {
                ...item,
                quantity:
                  action.payload.type === "add"
                    ? item.quantity + item.quantity / item.order_qty
                    : item.quantity - item.quantity / item.order_qty,
                order_qty:
                  action.payload.type === "add"
                    ? item.order_qty + 1
                    : item.order_qty - 1,
                extras: item.extras.map((extra) => ({
                  ...extra,
                  quantity:
                    action.payload.type === "add"
                      ? extra.quantity + extra.quantity / item.order_qty
                      : extra.quantity - extra.quantity / item.order_qty,
                })),
              }
            : item,
        ),
        plans: cartState.plans,
      };
    }

    case "REDUCE_PRODUCT_QTY_IN_CART": {
      const isItemInCart = cartState.products.find(
        (item) => item.slug === action.payload,
      )?.quantity;
      if (typeof isItemInCart === "number" && isItemInCart > 1) {
        return {
          products: cartState.products.map((item) =>
            item.slug === action.payload
              ? { ...item, quantity: item.quantity - 1 }
              : item,
          ),
          plans: cartState.plans,
        };
      } else {
        return {
          products: cartState.products.filter(
            (item) => item.slug !== action.payload,
          ),
          plans: cartState.plans,
        };
      }
    }

    case "REMOVE_PRODUCT_FROM_CART": {
      return {
        products: cartState.products.filter(
          (item) => item.slug !== action.payload,
        ),
        plans: cartState.plans,
      };
    }

    case "DELETE_ALL_ITEMS_IN_CART": {
      return {
        products: [],
        plans: [],
      };
    }

    case "ADD_EXTRA_TO_CART": {
      return {
        products: cartState.products.map((item) => {
          const isExtraInItem = item?.extras?.some(
            (extra) => extra.slug === action.payload.slug,
          );
          if (isExtraInItem) {
            return {
              ...item,
              extras: item?.extras?.map((extra) =>
                extra.slug === action.payload.slug
                  ? { ...extra, quantity: extra.quantity + 1 }
                  : extra,
              ),
            };
          } else {
            const newExtra = { ...action.payload, quantity: 1 };
            return {
              ...item,
              extras:
                item.slug === action.payload.product_slug
                  ? [...item.extras, newExtra]
                  : item.extras,
            };
          }
        }),
        plans: cartState.plans,
      };
    }

    case "REMOVE_EXTRA_FROM_CART": {
      return {
        products: cartState.products.map((item) => {
          const isExtraInItem = item?.extras?.some(
            (extra) =>
              extra.slug === action.payload.extra_slug && extra.quantity > 1,
          );
          if (isExtraInItem) {
            return {
              ...item,
              extras: item?.extras?.map((extra) =>
                extra.slug === action.payload.extra_slug
                  ? { ...extra, quantity: extra.quantity - 1 }
                  : extra,
              ),
            };
          } else {
            return {
              ...item,
              extras:
                item.slug === action.payload.product_slug
                  ? item.extras.filter(
                      (extra) => extra.slug !== action.payload.extra_slug,
                    )
                  : item.extras,
            };
          }
        }),
        plans: cartState.plans,
      };
    }

    case "ADD_PLAN_TO_CART": {
      return cartState.plans.find((item) => item.slug === action.payload.slug)
        ? cartState
        : {
            products: cartState.products,
            plans: [...cartState.plans, action.payload],
          };
    }

    case "REMOVE_PLAN_FROM_CART": {
      return {
        plans: cartState.plans.filter((item) => item.slug !== action.payload),
        products: cartState.products,
      };
    }

    default:
      return cartState;
  }
};

export const OrderProvider: FC<OrderProviderProps> = ({ children }) => {
  const cart = localStorage.getItem("cart");
  const [cartState, dispatch] = useReducer(
    cartReducer,
    cart
      ? JSON.parse(cart)
      : {
          products: [],
          plans: [],
        },
  );

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartState));
  }, [cartState]);

  return (
    <OrderContext.Provider
      value={{
        cartState,
        dispatch,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};
