const HeroAbout = () => {
  return (
    <section className="w-full h-fit bg-white px-[20px] py-[150px] bg-about-banner  bg-contain">
      <div className="container flex flex-row justify-center">
        <div className="w-full lg:w-4/5 text-center">
          <p className="text-[#041D05] font-[400] text-[18px] leading-[28px]">
            "OUR STORY Founded by Goke and Abimbola Balogun in 2010 as
            Fruitivegies Limited, before the change of name to So Fresh in 2013.
            So Fresh is Nigeria&apos;s pioneer and number one healthy food
            chain, providing fresh, delicious, nutrient-rich healthy meals and
            committed to promoting a healthy lifestyle. Over 10 years on, So
            Fresh has become the prime and preferred destination for people to
            make clean, safe, nutrient-rich & healthy food choices in Nigeria.
            FRESH IS BEST and that is how we win every day, selling a wide range
            of fresh salads, juices, smoothies, parfait, fiesta wraps, sandwich
            and other quick on the go healthy meals."
          </p>
        </div>
      </div>
    </section>
  );
};

export default HeroAbout;
