import about from "../../../../assests/images/AboutPage/mission.svg";
import vision from "../../../../assests/images/AboutPage/vision.svg";

const Mission = () => {
  return (
    <section className="bg-white px-[20px] py-[150px]">
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="w-10/12">
            <h2 className="text-[#FD9F28] font-[600] text-[40px] leading-[48px]">
              <span className="text-[#041D05]">Our</span> Mission
            </h2>

            <h4 className="text-[#041D05] font-semibold text-[18px] leading-[36px] mt-6">
              Inspiring you to Live Fresh, Live Healthy
            </h4>
            <p className="text-[#041D05] font-[400] text-[16px] leading-[29px] mt-1">
              We inspire people to live healthier and happier lives, by
              enriching them with fresh, wholesome, nutrient-rich and delicious
              meals. Not only do we serve real food, fresh and healthy from
              nature, at the core of what we do is PEOPLE
              <br />
              The people we empower people to:
              <br />
            </p>
            <p className="my-4 text-[#041D05] font-[400] text-[16px] leading-[29px]">
              - Make healthy food choices and live healthier lifestyles.
              <br />
              - Realise their weight-loss and fitness goals.
              <br />
              - Improve their health conditions, overall wellbeing and
              self-esteem.
              <br />
              - Be economically independent.
              <br />
            </p>

            <p className="text-[#041D05] font-[400] text-[16px] leading-[29px]">
              For us it's all about the PEOPLE we empower daily through the
              value we create. Our fresh healthy food is just the medium.
              <br />
              We feel a sense of responsibility to change the future of food as
              we know it today and so, our underlying philosophy is to provide
              good, clean nutrition and healthy living and remain a
              customer-centric organization in order to create sustainable value
              for our community
            </p>
          </div>
          <div>
            <img src={about} alt="about" className="w-full" />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-14">
          <div className="w-12/12 lg:w-10/12">
            <img src={vision} alt="vision" className="w-full" />
          </div>
          <div className="w-3/4">
            <h2 className="text-[#FD9F28] font-[600] text-[40px] leading-[48px]">
              <span className="text-[#041D05]">Our</span> Vision
            </h2>
            <p className="text-[#041D05] font-[400] text-[16px] leading-[29px] mt-6">
              To be the prime destination for Healthy Lifestyle in Africa.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mission;
