import {
  useEffect,
  useCallback,
  ReactElement,
  ReactFragment,
  ReactNode,
} from "react";
import { scrollToTop } from "../../../lib/utlis/helper";
import { IoMdClose } from "react-icons/io";

import styles from "./Modal.module.scss";

interface ModalProps {
  width?: string;
  title?: string;
  subTitle?: string;
  isOpen: boolean;
  displayCloseIcon?: boolean;
  onClose: () => void;
  modalClassName?: string;
  children: ReactElement | ReactNode | ReactFragment;
}

const Modal = ({
  width, // width should be a tailwind css max-width class like so max-w-[width]
  isOpen,
  onClose,
  displayCloseIcon,
  title,
  subTitle,
  modalClassName,
  children,
}: ModalProps) => {
  const closeModal = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    scrollToTop();

    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.addEventListener("keydown", handleEscapeKey);
    } else {
      document.body.style.overflow = "auto";
      document.removeEventListener("keydown", handleEscapeKey);
    }

    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [isOpen, closeModal]);

  return (
    <section className={styles.modalOverlay}>
      <div
        className={`${styles.modal} w-[90%] ${width && width}  ${
          modalClassName && modalClassName
        }`}
      >
        <div className={styles.modalHeader}>
          <div className={styles.modalHeaderTop}>
            {title && (
              <div className={styles.modalTitle}>
                <h5 className={styles.modalHeaderTitle}>{title}</h5>
              </div>
            )}
            {displayCloseIcon && (
              <button
                onClick={closeModal}
                className={`${styles.modalCloseBtn} ${
                  (title === "" || !title) && "w-full ml-auto"
                }`}
              >
                <IoMdClose />
              </button>
            )}
          </div>

          {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
        </div>

        <div className={styles.modalBody}>{children}</div>
      </div>
    </section>
  );
};

export default Modal;
