import Layout from "../../components/layouts/Layout";
import { getCategoryWithBlogs } from "../../lib/handlers/blogs";
import { useQuery } from "@tanstack/react-query";
import { Oval } from "react-loader-spinner";
import { useLayoutEffect } from "react";
import { Link, useParams } from "react-router-dom";
import HomeSubscription from "../../components/layouts/Home/HomeSubscription";

const SingleBlogCategory = () => {
  const { slug } = useParams();

  const {
    data: categoryWithBlogsData,
    isLoading: isCategoryWithBlogsDataLoading,
  } = useQuery(
    ["category-with-blogs", slug],
    () => getCategoryWithBlogs(slug as string),
    { enabled: Boolean(slug) }
  );

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Layout title="Healthy Food Made for You" description="Blogs Page">
      <main className="container">
        {isCategoryWithBlogsDataLoading ? (
          <div className="w-full flex items-center justify-center my-32">
            <Oval wrapperClass="ml-2" height={50} width={50} strokeWidth={5} />
          </div>
        ) : categoryWithBlogsData ? (
          <section className="px-4 py-10" key={categoryWithBlogsData.title}>
            <h1 className="text-[#041D05] text-[30px] font-semibold leading-[1.1] text-center lg:text-start">
              {categoryWithBlogsData.title}
            </h1>

            <div className="w-full grid grid-cols-1 md:grid-cols-3 items-center justify-center gap-8 lg:gap-10 lg:grid-cols-4 py-10">
              {categoryWithBlogsData.blogs.map((blog: any, index: number) => (
                <Link to={`/blogs/${blog.slug}`}>
                  <figure
                    className="w-[270px] flex flex-col items-center justify-center hover:shadow-blog-card rounded-lg p-4"
                    key={blog.id}
                  >
                    <div className="w-full aspect-square flex group/blogImage overflow-hidden">
                      <img
                        className="group-hover/blogImage:scale-[1.1] transition-all duration-300 ease-in"
                        src={blog.images[0]}
                        alt={blog.title}
                      />
                    </div>

                    <figcaption className="w-full flex flex-col gap-3 pt-4">
                      <span className="w-fit bg-[#F4E4D7] text-[#A17759] text-xs font-semibold leading-[1.4] rounded-[4px] px-[10px] py-[5px]">
                        {categoryWithBlogsData.title}
                      </span>
                      <h2 className="text-[#374935] text-[20px] font-semibold leading-[1.4] line-clamp-2 text-ellipsis">
                        {blog.title}
                      </h2>
                      <p className="text-[#041D05] text-[12px] font-normal leading-[1.4]">
                        Published On:
                        <span className="text-[#339E35] ps-1">
                          {blog.created_at}
                        </span>
                      </p>
                    </figcaption>
                  </figure>
                </Link>
              ))}
            </div>
          </section>
        ) : (
          <div className="w-full min-h-[60dvh] flex items-center justify-center">
            <h1 className="text-5xl text-[#041d05] font-semibold">
              No Blogs Yet
            </h1>
          </div>
        )}
      </main>

      <HomeSubscription />
    </Layout>
  );
};

export default SingleBlogCategory;
