// REACT DEFAULTS
import { Link } from "react-router-dom";

// COMPONENTS, LIBs & UTILs
import { scrollToTop } from "../../../lib/utlis/helper";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

// STYLES
import styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <footer className={styles.footerContainer}>
      <div className={`container ${styles.footer}`}>
        <div className={styles.footerSocialsContainer}>
          <div className={styles.footerSocials}>
            <Link
              className={styles.footerSocialIcons}
              target={"_blank"}
              to="https://www.facebook.com/sofreshng"
            >
              <FaFacebookF />
            </Link>
            <Link
              className={styles.footerSocialIcons}
              target={"_blank"}
              to="https://twitter.com/sofreshng"
            >
              <FaSquareXTwitter />
            </Link>
            <Link
              className={styles.footerSocialIcons}
              target={"_blank"}
              to="https://www.instagram.com/sofreshng/"
            >
              <FaInstagram />
            </Link>
          </div>
          <small className={styles.footerCopyright}>
            SoFresh Neighbourhood Market &copy; {new Date().getFullYear()}.
          </small>
        </div>

        <div className={styles.footerLinksContainer}>
          <div className={styles.footerCategory}>
            <h5 className={styles.footerCategoryTitle}>Company</h5>

            <ul className={styles.footerLinks}>
              <li className={styles.footerLink}>
                <Link
                  className={styles.footerLinkItem}
                  onClick={() => scrollToTop()}
                  to="/about"
                >
                  The So Fresh Story
                </Link>
              </li>
            </ul>
          </div>

          <div className={styles.footerCategory}>
            <h5 className={styles.footerCategoryTitle}>Policies</h5>

            <ul className={styles.footerLinks}>
              <li className={styles.footerLink}>
                <Link
                  className={styles.footerLinkItem}
                  onClick={() => scrollToTop()}
                  to="/return-policy"
                >
                  Return Policy
                </Link>
              </li>
              <li className={styles.footerLink}>
                <Link
                  className={styles.footerLinkItem}
                  onClick={() => scrollToTop()}
                  to="/privacy-policy"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>

          <div className={styles.footerCategory}>
            <h5 className={styles.footerCategoryTitle}>Support</h5>

            <ul className={styles.footerLinks}>
              <li className={styles.footerLink}>
                <Link
                  className={styles.footerLinkItem}
                  onClick={() => scrollToTop()}
                  to="/contact"
                >
                  Contact Us
                </Link>
              </li>
              <li className={styles.footerLink}>
                <Link
                  className={styles.footerLinkItem}
                  onClick={() => scrollToTop()}
                  to="/locations"
                >
                  Locations
                </Link>
              </li>
            </ul>
          </div>

          <div className={styles.footerCategory}>
            <h5 className={styles.footerCategoryTitle}>Media</h5>

            <ul className={styles.footerLinks}>
              <li className={styles.footerLink}>
                <Link
                  className={styles.footerLinkItem}
                  onClick={() => scrollToTop()}
                  to="/blogs"
                >
                  Blogs
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
