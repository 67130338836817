import { axiosClient } from "../utlis/axiosClient";

export enum Subject {
  INQUIRY = "Inquiry",
  COMPLAINT = "Complaint",
  SUGGESTION = "Suggestion",
}

export type ContactFormData = {
  name: string;
  email: string;
  phone_no: string;
  subject: Subject;
  content: string;
};

export const submitContactForm = async ({
  data,
}: {
  data: ContactFormData;
}): Promise<ContactFormData> => {
  try {
    const response = await axiosClient.post(`public/complains`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.info({ response })
    return response.data;
  } catch (error) {
    console.error({ error });
    throw new Error("An error occured. Please try again.");
  }
};
