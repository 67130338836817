import { Location } from "react-router-dom";
/*=============================================
=               HELPER FUNCTIONS              =
=============================================*/

// FUNCTION TO SCROLL TO THE TOP OF A PAGE
export const scrollToTop = () => {
  return setTimeout(() => {
    window.scrollTo(0, 0);
  });
};

// FUNCTION TO CONVERT STRING NUMs or NUMs to Nigerian Naira.
export const formatToNigerianNaira = (num: number | string): string => {
  // Convert input to a number
  const parsedNum = typeof num === "string" ? parseFloat(num) : num;

  // Check if input is valid
  if (isNaN(parsedNum)) {
    throw new Error(
      "Invalid input, please provide a number or a string of a number.",
    );
  }
  // Format to Nigerian Naira currency format
  const formatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  });

  return formatter.format(parsedNum);
};

// FUNCTION TO REMOVE THE LAST PATH OF A URL(React-router-dom LOCATION)
export const removeSubstringAfterLastSlash = (location: Location): string => {
  const { pathname } = location;
  const lastSlashIndex = pathname.lastIndexOf("/");

  if (lastSlashIndex !== -1) {
    return pathname.substring(0, lastSlashIndex);
  }

  return pathname;
};

// FUNCTION TO REMOVE THE LAST PATH OF A URL
export const removeStringAfterLastSlash = (str: any): string => {
  const lastSlashIndex = str.lastIndexOf("/");

  if (lastSlashIndex === -1) {
    // No slash found, return the original string
    return str;
  }

  const modifiedStr = str.substring(0, lastSlashIndex);
  return modifiedStr;
};

/*----------  SORT ARRAYS IN ORDER OF LAST ID FIRST  ----------*/
export const sortFn = (a: { id: number }, b: { id: number }) => b.id - a.id;

/*=====     End of HELPER FUNCTIONS    ======*/

/*=============================================
=                OLD FUNCTIONS                =
=============================================*/

export function ucfirst(str: string | any): string {
  if (typeof str === "string") {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  } else {
    return str;
  }
}

export const localStorageChecker = () => {
  const storage = typeof window.localStorage;
  return storage ? true : false;
};

export const saveToLocalStorage = (name: string, item: string) => {
  if (localStorageChecker()) {
    localStorage.setItem(name, item);
    return true;
  }
};

export const getLocalStorageItem = (name: string) => {
  if (localStorageChecker()) {
    const item = localStorage.getItem(name);
    return item;
  }
};

export const removeLocalStorageItem = (name: string) => {
  if (localStorageChecker()) {
    localStorage.removeItem(name);
  }
};

export const createRandomId = () => {
  return Math.floor(Math.random() * 10000000000 + 1);
};

export const checkCrossOriginUrl = (url: string) => {
  if (!url || url === "about:blank") {
    return false;
  }

  const parser = document.createElement("a");
  parser.href = url;

  const current = window.location;

  if (
    (parser.hostname && current.hostname !== parser.hostname) ||
    (parser.protocol && current.protocol !== parser.protocol)
  ) {
    return true;
  }
  return false;
};

/*=====  End of Section comment block  ======*/
