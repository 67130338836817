// REACT DEFAULTS
import { useContext } from "react";
import { HiMinusSmall, HiPlusSmall } from "react-icons/hi2";
import { OrderContext } from "../../../context/OrderContext";
import styles from "./ExtrasCard.module.scss";
import { formatToNigerianNaira } from "../../../lib/utlis/helper";

const ExtrasCard = ({
  imgURL,
  extrasName,
  extraPrice,
  singleProductsDataSlug,
  extra,
}: TExtrasCard) => {
  const { cartState, dispatch } = useContext(OrderContext);

  const quantity =
    cartState.products
      .find((item) => item.slug === singleProductsDataSlug)
      ?.extras?.find((item) => item.slug === extra.slug)?.quantity ?? 0;

  return (
    <figure className={styles.extrasCard}>
      <img src={imgURL} alt={extrasName} className={styles.extrasImage} />
      <figcaption className={styles.extrasCardBody}>
        <p className={styles.extrasName}>{extrasName}</p>
        <p className={styles.extrasPrice}>
          {formatToNigerianNaira(extraPrice)}
        </p>

        <div className={styles.extrasControls}>
          <div className={styles.extrasControlGroup}>
            <button
              onClick={() =>
                dispatch({
                  type: "REMOVE_EXTRA_FROM_CART",
                  payload: {
                    extra_slug: extra.slug,
                    product_slug: singleProductsDataSlug,
                  },
                })
              }
              className={styles.extrasQuantityMinus}
              disabled={quantity < 1}
            >
              <HiMinusSmall />
            </button>
            <span className={styles.extrasQuantity}>{quantity}</span>
            <button
              onClick={() =>
                dispatch({
                  type: "ADD_EXTRA_TO_CART",
                  payload: { ...extra, product_slug: singleProductsDataSlug },
                })
              }
              className={styles.extrasQuantityPlus}
              disabled={quantity === 20}
            >
              <HiPlusSmall />
            </button>
          </div>
        </div>
      </figcaption>
    </figure>
  );
};

export default ExtrasCard;
