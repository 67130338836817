import bubble from "../../../../assests/images/AboutPage/bubble.svg";
import styles from "./SofreshWay.module.scss";

const SofreshWay = () => {
  return (
    <section className="bg-[#FFFAF4] px-[20px] pt-[90px] pb-[150px]">
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className={`text-center ${styles.soFreshWay}`}>
            <img
              src={bubble}
              alt="bubble"
              className={`mx-auto ${styles.stack}`}
            />
            <h6
              // style={{ marginTop: '-171px' }}
              className={`max-w-[18ch] text-[#FD9F28] font-[600] text-[20px] leading-[28px] ps-10 pb-4 ${styles.stack}`}
            >
              We prioritize relationship, connection, engagement, and the
              well-being of our customers
            </h6>
          </div>
          <div className="mt-10">
            <h2 className="text-[#FD9F28] font-[600] text-[32px] leading-[48px]">
              <span className="text-[#041D05]">THE</span> SO FRESH EXPERIENCE
            </h2>
            <p className="text-[#041D05] font-[400] text-[16px] leading-[29px] mt-6">
              The So Fresh Experience shall be defined by easy access to
              quality, fresh, healthy meals, in a welcoming, cozy, and vibrant
              ambiance with a friendly, passionate, and caring team, that offers
              delightfully memorable service."
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SofreshWay;
