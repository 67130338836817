// COMPONENTS
import SectionHeader from "../SectionHeader";
import parse from "html-react-parser";
import { BLOG_DATA } from "../utils";

// IMAGES & ICONS
import { VscArrowRight } from "react-icons/vsc";

// STYLES
import styles from "./HomeBlogCards.module.scss";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getLatestBlogs } from "../../../../lib/handlers/blogs";
import { Oval } from "react-loader-spinner";

const HomeBlogCards = () => {
  const { data: blogsData, isLoading: isBlogsDataLoading } = useQuery(
    ["blogs"],
    () => getLatestBlogs(),
    { refetchOnWindowFocus: false, retry: 1 },
  );

  return (
    <section className={`container ${styles.homeBlogCards}`}>
      {isBlogsDataLoading ? (
        <div className="w-full flex align-middle justify-center my-32">
          <Oval wrapperClass="ml-2" height={50} width={50} strokeWidth={5} />
        </div>
      ) : blogsData && blogsData?.length > 0 ? (
        <>
          <SectionHeader
            title="Latest"
            caption="News"
            linkText="Read More"
            LinkHref="/blogs"
          />

          <div className={styles.blogCardsContainer}>
            {blogsData?.map((blog) => (
              <article className={styles.blogCardItem} key={blog.slug}>
                <figure className={styles.blogCardBody}>
                  <div className={styles.overflowHidden}>
                    <img
                      className={styles.blogCardImage}
                      src={blog.images[0]}
                      alt={blog.title}
                      loading="eager"
                    />
                  </div>

                  <figcaption className="w-100 flex flex-col gap-5">
                    <span
                      className={`${styles.blogCardTitle} line-clamp-1 text-elipsis`}
                    >
                      {blog.title}
                    </span>

                    <div
                      className={`${styles.blogCardText} line-clamp-3 text-elipsis`}
                    >
                      {parse(blog.article)}
                    </div>
                  </figcaption>

                  <div className={styles.blogCardFooter}>
                    <small className={styles.blogCardDate}>
                      {blog.created_at}
                    </small>
                    <Link
                      to={`/blogs/${blog.slug}`}
                      className={styles.blogCardBtn}
                    >
                      <span>Read More</span>
                      <VscArrowRight className={styles.blogCardBtnIcon} />
                    </Link>
                  </div>
                </figure>
              </article>
            ))}
          </div>
        </>
      ) : (
        <>
          <SectionHeader
            title="Latest"
            caption="News"
            linkText="Read More"
            LinkHref="/blogs"
          />

          <div className={styles.blogCardsContainer}>
            {BLOG_DATA?.map((blog) => (
              <article className={styles.blogCardItem} key={blog.blogTitle}>
                <figure className={styles.blogCardBody}>
                  <div className={styles.overflowHidden}>
                    <img
                      className={styles.blogCardImage}
                      src={blog.blogImgUrl}
                      alt={blog.blogTitle}
                      loading="eager"
                    />
                  </div>

                  <figcaption className="w-100 flex flex-col gap-5">
                    <span
                      className={`${styles.blogCardTitle} line-clamp-1 text-elipsis`}
                    >
                      {blog.blogTitle}
                    </span>

                    <div
                      className={`${styles.blogCardText} line-clamp-3 text-elipsis`}
                    >
                      {parse(blog.blogText)}
                    </div>
                  </figcaption>

                  <div className={styles.blogCardFooter}>
                    <small className={styles.blogCardDate}>
                      {blog.blogDate}
                    </small>
                    <Link
                      to={`/${blog.blogLinkHref}`}
                      className={styles.blogCardBtn}
                    >
                      <span>Read More</span>
                      <VscArrowRight className={styles.blogCardBtnIcon} />
                    </Link>
                  </div>
                </figure>
              </article>
            ))}
          </div>
        </>
      )}
    </section>
  );
};

export default HomeBlogCards;
