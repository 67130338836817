// REACT DEFAULTS
import { useState, useContext, ChangeEvent, FormEventHandler } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

// LIB & UTILS
import { loginUser } from "../../../lib/handlers/authhandler";
// import { toast } from '../component/Toast'
import { useMutation } from "@tanstack/react-query";
import { AuthContext } from "../../../context/AppContext";

// IMAGES & ICONS
import BrandLogo from "../../../assests/images/sofreshLogo.png";
import AuthImage from "../../../assests/images/AuthPages/authImage.webp";
import { FaArrowLeft, FaArrowRight, FaStar } from "react-icons/fa";

// STYLES
import styles from "./Login.module.scss";
import { OrderContext } from "../../../context/OrderContext";

const Login = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = searchParams.get("location");
  const { handleAuthState } = useContext(AuthContext);
  const { cartState } = useContext(OrderContext);
  // console.log('cartState: ', cartState.length === 0)

  const [userData, setUserData] = useState<IUserLogin>({
    email: "",
    password: "",
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const { mutate: loginUserMutation } = useMutation(loginUser, {
    // const { mutate: loginUserMutation, isLoading } = useMutation(loginUser, {
    onSuccess: async (res: any) => {
      handleAuthState(res);
      if (location) {
        navigate(`/${location}`);
      } else if (cartState.products.length > 0) {
        navigate("/cart");
      } else {
        navigate("/menu");
      }
    },
    onError: (e: any) => {
      console.error({ e });
      // toast.error(e)
    },
  });

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    const values: IUserLogin = userData;
    loginUserMutation(values);
  };

  return (
    <main className={styles.login}>
      <section className={styles.loginSection}>
        <div className={styles.authFormContainer}>
          <div className={styles.brandLogoContainer}>
            <Link className={styles.brandLogo} to={"/"}>
              <img
                // className={styles.brandLogo}
                src={BrandLogo}
                alt="brand logo"
                loading="eager"
              />
            </Link>
          </div>

          <div className={styles.formHeading}>
            <h1 className={styles.formTitle}>Welcome Back</h1>
            <p className={styles.formSubTitle}>
              Kindly enter your email and password to access account
            </p>
          </div>

          <form className={styles.authForm} onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <label className={styles.formFieldLabel} htmlFor="email">
                Email Address
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className={styles.formInputField}
                placeholder="Enter your email address"
                value={userData.email}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label className={styles.formFieldLabel} htmlFor="password">
                Password Address
              </label>
              <input
                type="password"
                name="password"
                id="password"
                className={styles.formInputField}
                placeholder="Enter your password"
                value={userData.password}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className={styles.formSubmit}>
              <button className={styles.submitBtn}>Log In</button>

              <small className={styles.formRedirectText}>
                Don&apos;t have an account?
                <span>
                  <Link to={"/sign-up"} className={styles.formRedirect}>
                    Create account
                  </Link>
                </span>
              </small>
            </div>
          </form>
        </div>

        <div className={styles.imgContainer}>
          <img
            className={styles.authImage}
            src={AuthImage}
            alt="smiling customers"
          />

          <article className={styles.miniTestimonials}>
            <blockquote className={styles.blockQuote}>
              <p>
                &ldquo;So Fresh is really what it says it is, &ldquo;So
                Fresh&rdquo;. I have never received an order that isnt fresh.
                Great customer service also&rdquo;
              </p>
            </blockquote>

            <div className={styles.ratingControls}>
              <div className={styles.testimonialCredits}>
                <p className={styles.testimonialName}>Remilekun Salami</p>
                <div className={styles.ratings}>
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
              </div>

              <div className={styles.controls}>
                <FaArrowLeft className={styles.controlIcon} />
                <FaArrowRight className={styles.controlIcon} />
              </div>
            </div>
          </article>
        </div>
      </section>
    </main>
  );
};

export default Login;
