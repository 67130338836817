import "../QualityStatement/QualityStatement.css";
const QualityStatement = () => {
  return (
    <section className="px-[20px] py-[100px] bg-[#FD9F28] bg-center bg-no-repeat bg-cover quality">
      <div className="flex flex-row justify-center">
        <div className="w-2/2 lg:w-2/5 text-center">
          <h2 className="text-[#fff] font-[600] text-[40px] leading-[48px]">
            Quality Statement
          </h2>
          <p className="text-[#fff] font-[400] text-[16px] leading-[25px] mt-6">
            So Fresh is committed to providing the highest quality products and
            services always. Quality to us means ensuring freshness,
            wholesomeness, hygiene, safety, tastiness, nutritive value, good
            appearance and nice presentation of all products we offer.
          </p>
        </div>
      </div>
    </section>
  );
};

export default QualityStatement;
