import call from "../../../../assests/images/ContactPage/call.svg";
import map from "../../../../assests/images/ContactPage/map.svg";
import contact from "../../../../assests/images/ContactPage/email.svg";

const Hotlines = () => {
  return (
    <section className="bg-[#FFFAF4] px-[20px] py-[100px] border-t border-b border-[#E2D2BE66]">
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-10">
          <div className="flex flex-row">
            <div className="me-3">
              <img src={call} alt="call" />
            </div>
            <div className="mt-2">
              <h3 className="text-[#041D05] font-[500] text-[20px] leading-[25px]">
                Whatsapp only
              </h3>
              <p className="text-[#041D05] font-[300] text-[16px] leading-[21px] mt-1">
                08062868430
              </p>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="me-3">
              <img src={map} alt="call" />
            </div>
            <div className="mt-2">
              <h3 className="text-[#041D05] font-[500] text-[20px] leading-[25px]">
                Head Office
              </h3>
              <p className="text-[#041D05] font-[300] text-[16px] leading-[21px] mt-1">
                71, Opebi Road, Ikeja, Lagos State
              </p>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="me-3">
              <img src={contact} alt="call" />
            </div>
            <div className="mt-2">
              <h3 className="text-[#041D05] font-[500] text-[20px] leading-[25px]">
                Inquiries
              </h3>
              <p className="text-[#041D05] font-[300] text-[16px] leading-[21px] mt-1">
                info@sofreshng.com
              </p>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="me-3">
              <img src={contact} alt="call" />
            </div>
            <div className="mt-2">
              <h3 className="text-[#041D05] font-[500] text-[20px] leading-[25px]">
                Complaints
              </h3>
              <p className="text-[#041D05] font-[300] text-[16px] leading-[21px] mt-1">
                complaints@sofreshng.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hotlines;
