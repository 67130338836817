import React from "react";

type ArticleProps = {
  title: string;
  unorderedListItems?: string[];
  orderedListItems?: string[];
  paragraphs?: string[];
};

const Article = ({
  title,
  orderedListItems,
  unorderedListItems,
  paragraphs,
}: ArticleProps) => {
  return (
    <article className="w-full flex flex-col justify-center gap-4 md:gap-5">
      {orderedListItems && orderedListItems.length > 0 ? (
        <>
          <h2 className="text-[#041D05] text-xl md:text-3xl font-bold">
            {title}
          </h2>
          <ol style={{ listStyle: "revert" }} className="px-8">
            {orderedListItems.map((item, index) => (
              <li
                key={index}
                className="text-[#041D05] text-sm font-light leading-[1.6]"
              >
                {item}
              </li>
            ))}
          </ol>
        </>
      ) : (
        <>
          <h2 className="text-[#041D05] text-xl md:text-3xl font-bold">
            {title}
          </h2>
          <>
            {paragraphs &&
              paragraphs?.map((paragraph, index) => (
                <p
                  key={index}
                  className="text-[#041D05] text-sm font-light leading-[1.6]"
                >
                  {paragraph}
                </p>
              ))}
            {unorderedListItems &&
              unorderedListItems.map((item, index) => (
                <li
                  key={index}
                  className="text-[#041D05] text-sm font-light leading-[1.6]"
                >
                  {item}
                </li>
              ))}
          </>
        </>
      )}
    </article>
  );
};

export default Article;
