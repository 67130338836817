const Aside = () => {
  return (
    <aside className="w-2/5 xl:w-1/3bg-[#FFFBF8] sticky top-[12%]  xl:top-[15%] self-start ps-4 md:ps-7 xl:ps-0 hidden sm:flex flex-col gap-2 md:gap-4">
      <h1 className="text-[#FD9F28] text-lg font-semibold pb-4">
        Privacy Policy
      </h1>

      <nav className="w-full flex flex-col gap-2 md:gap-4">
        <div className="w-full flex flex-col gap-2 md:gap-4">
          <p className="text-[#041d05cc] text-sm font-normal leading-[1.4]">
            Introduction
          </p>
          <p className="text-[#041d05cc] text-sm font-normal leading-[1.4]">
            Content
          </p>
        </div>

        <ol
          style={{ listStyle: "revert" }}
          className="px-6 md:px-8 w-full flex flex-col gap-2 md:gap-4"
        >
          <li className="text-[#041d05cc] text-sm font-normal leading-[1.4]">
            How do we use your information?
          </li>
          <li className="text-[#041d05cc] text-sm font-normal leading-[1.4]">
            How do we protect visitor information?
          </li>
          <li className="text-[#041d05cc] text-sm font-normal leading-[1.4]">
            Do we use &ldquo;cookies&rdquo;?
          </li>
          <li className="text-[#041d05cc] text-sm font-normal leading-[1.4]">
            Third-party disclosure
          </li>
          <li className="text-[#041d05cc] text-sm font-normal leading-[1.4]">
            Third-party links
          </li>
          <li className="text-[#041d05cc] text-sm font-normal leading-[1.4]">
            What Choices Does So Fresh Offer About Personal Information?
          </li>
          <li className="text-[#041d05cc] text-sm font-normal leading-[1.4]">
            Questions and Feedback
          </li>
          <li className="text-[#041d05cc] text-sm font-normal leading-[1.4]">
            Card Payments
          </li>
          <li className="text-[#041d05cc] text-sm font-normal leading-[1.4]">
            Privacy of Information
          </li>
          <li className="text-[#041d05cc] text-sm font-normal leading-[1.4]">
            Security
          </li>
          <li className="text-[#041d05cc] text-sm font-normal leading-[1.4]">
            Credit/Debit Card Payment
          </li>
          <li className="text-[#041d05cc] text-sm font-normal leading-[1.4]">
            Limits on Use
          </li>
          <li className="text-[#041d05cc] text-sm font-normal leading-[1.4]">
            Amendments
          </li>
          <li className="text-[#041d05cc] text-sm font-normal leading-[1.4]">
            Liability
          </li>
        </ol>
      </nav>
    </aside>
  );
};

export default Aside;
