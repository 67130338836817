import { useContext } from "react";
import { ProductContext } from "../context/ProductContextProvider";

const useProductContext = (): TUseProductContextType => {
  const productContextData = useContext<TUseProductContextType>(ProductContext);
  if (!productContextData) {
    throw new Error(
      "useProductContext must be used within a ProductContextProvider",
    );
  }
  return productContextData;
};

export default useProductContext;
