// REACT & DEFAULTS
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";

// COMPONENTS, LIBs & UTILs
import { getSingleProducts } from "../../../lib/handlers/productHandler";
import { formatToNigerianNaira } from "../../../lib/utlis/helper";

// IMAGES & ICONS
import { Oval } from "react-loader-spinner";
import { VscClose } from "react-icons/vsc";

// STYLES
import styles from "./OrderPlusExtraModal.module.scss";
import { OrderContext } from "../../../context/OrderContext";
import SingleOrderModal from "../SingleOrderModal";

const OrderPlusExtraModal = ({
  toggleModal,
  slug,
  handleClick,
}: IOrderPlusExtraModalProps) => {
  const { cartState } = useContext(OrderContext);

  // GET SINGLE MENU/PRODUCT
  const {
    data: singleProductsData,
    isLoading: isSingleProductsDataLoading,
    isSuccess,
  } = useQuery(["single-products"], () => getSingleProducts(`${slug}`));
  // console.log('singleProductsData: ', singleProductsData)
  // console.log('isSingleProductsDataLoading: ', isSingleProductsDataLoading)
  // console.log('isRefetching: ', isRefetching)

  // FIND SELECTED PRODUCT
  const selectedProduct = cartState?.products.find(
    (item) => item.slug === slug,
  );

  // CALCULATE TOTAL PRICE OF ANY EXTRA ADDED
  const totalExtras =
    selectedProduct?.extras?.reduce(
      (initialExtraPrice, currentExtra) =>
        initialExtraPrice + currentExtra.quantity * Number(currentExtra.price),
      0,
    ) ?? 0;

  // CALCULATE TOTAL PRICE OF SINGLE PRODUCT ADDED PLUS ITS ADDED EXTRAS
  const productPrice =
    (selectedProduct?.quantity ?? 0) *
      (selectedProduct?.discount
        ? Number(selectedProduct?.discount)
        : Number(selectedProduct?.price)) +
    totalExtras;
  // const productPrice =
  //   (selectedProduct?.quantity ?? 0) * Number(selectedProduct?.price) +
  //   totalExtras
  // console.log('totalPrice: ', totalPrice)
  // console.log('selectedProduct: ', selectedProduct)

  return (
    <section className={styles.modal}>
      <div className={styles.modalOverlay}>
        <div className={styles.modalContent}>
          <div className={styles.modalHeading}>
            <button className={styles.modalCloseBtn} onClick={toggleModal}>
              <VscClose />
            </button>
          </div>

          <div className={styles.modalBody}>
            {isSingleProductsDataLoading ? (
              <div className={styles.modalLoader}>
                <Oval
                  wrapperClass="mx-auto"
                  height={50}
                  width={50}
                  strokeWidth={5}
                />
              </div>
            ) : (
              isSuccess && (
                <div className={styles.modalBodyContent}>
                  <SingleOrderModal singleProductsData={singleProductsData} />

                  <div className={styles.addToCartBtnContainer}>
                    <button
                      className={styles.addToCartBtn}
                      disabled={!selectedProduct ? true : false}
                      onClick={handleClick}
                    >
                      {`Add To Cart ${
                        productPrice > 0
                          ? formatToNigerianNaira(productPrice)
                          : ""
                      }`}
                    </button>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OrderPlusExtraModal;
